import React, { Fragment, useRef, Profiler } from "react";
import { StyleSheet, View } from "react-native";
import * as yup from "yup";
import { Formik } from "formik";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { TextInput, Scaffold } from "components";
import { useNavigation } from "@react-navigation/native";
import { manufacturerRoutes } from "lib/routing/routes";
import { createOrUpdateProduct } from "./utils";
import fixNull from "lib/fix-null";

export default function Productdescription() {
  const manufacturerId = useNavigationParam("manufacturerId");
  const id = useNavigationParam("id");
  const description = useNavigationParam("description") || "";
  const customBack = useCustomBack();
  const isSubmitting = useRef(false);
  const navigation = useNavigation();

  return (
    <Scaffold>
      <Formik
        validationSchema={validatorSchema}
        initialValues={{
          description: fixNull(description),
        }}
        onSubmit={async (values) => {
          if (isSubmitting.current === false) {
            isSubmitting.current = true;
            const product = await createOrUpdateProduct(manufacturerId, {
              id,
              ...values,
            });
            isSubmitting.current = false;
            return navigation.navigate(manufacturerRoutes.editProduct, {
              ...product,
              manufacturerId: product.manufacturer?.id,
            });
          }
        }}
      >
        {({ submitForm }) => {
          customBack(submitForm);
          return (
            <Fragment>
              <TextInput
                name="description"
                placeholder="Enter a description for the product"
              />
              <SubmitErrorHandler />
            </Fragment>
          );
        }}
      </Formik>
    </Scaffold>
  );
}

const validatorSchema = yup.object().shape({
  description: yup.string().required("Field is required"),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    paddingHorizontal: 40,
    backgroundColor: "white",
  },
});
