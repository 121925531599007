import React, { FunctionComponent, useEffect, useState } from "react";

import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer, Product } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import {
  homeRoutes,
  manufacturerRoutes,
  productRoutes,
} from "lib/routing/routes";
import { useNavigation } from "@react-navigation/native";
import ActionableList from "components/actionable-list/ActionableList";
import { mapProductsToUI } from "screens/AddProduct/AddProduct.utils";
import useNavigationParam from "lib/use-navigation-param";
import { Text } from "react-native-elements";
import Space from "components/Space/Space";
import FloatingButton from "components/buttons/FloatingButton";
import { Row } from "react-native-easy-grid";
import { ActivityIndicator, View } from "react-native";
import manufacturerProductsListStyle from "screens/Manufacturers/ManufacturerProducts/ManufacturerProductsList.style";
import { Center } from "components/utils/Center";

interface Props {
  loading: boolean;
  error: boolean;
  items: ActionableListItem[];
  onItemPress(item: ActionableListItem): void;
  onPublishPress(): void;
}

const ManufacturerProductsList: FunctionComponent<Props> = ({
  loading,
  error,
  items,
  onItemPress,
  onPublishPress,
}) => {
  if (error)
    return (
      <Center>
        <Text>Error loading manufacturer products.</Text>
      </Center>
    );

  return (
    <>
      <ActionableList
        items={items}
        loading={loading}
        onItemPress={onItemPress}
      />
      <FloatingButton
        title="Manufacturer Details"
        loading={false}
        onPress={onPublishPress}
      />
    </>
  );
};

export default ManufacturerProductsList;
