import React, { useRef, useState, useEffect, useCallback } from "react";
import useNavigationParam from "lib/use-navigation-param";
import useLoadDocuments from "lib/use-load-documents";
import { Product, Buyer, ProductOwnership } from "models";
import ActionableList from "components/actionable-list/ActionableList";
import { mapProductsToUI } from "./AssociateBuyerProduct.utils";
import { DataStore } from "@aws-amplify/datastore";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { useNavigation } from "@react-navigation/native";
import { buyerRoutes } from "lib/routing/routes";
import alert from "lib/alert";
import { sortBy } from "lodash";

export default function AssociateBuyerProduct() {
  const navigation = useNavigation();
  const id = useNavigationParam("id") as string;
  const [productItems, setProductItems] = useState<ActionableListItem[]>([]);
  const [isLoadingProducts, products] = useLoadDocuments(Product, (c) =>
    c.isPublished("eq", true)
  );
  const [isLoadingProductsOwnership, productOwnership] = useLoadDocuments(
    ProductOwnership
  );
  const allowClick = useRef(true);

  const loading = isLoadingProducts || isLoadingProductsOwnership;

  useEffect(() => {
    if (!loading) {
      const newItems = products.filter((product) => {
        const po = productOwnership.find(
          (po) => po.product?.id === product.id && po.buyer?.id === id
        );
        if (po) return false;
        return true;
      });

      setProductItems(mapProductsToUI(sortBy(newItems, (m) => m.name)));
    }
  }, [productOwnership, products, isLoadingProductsOwnership, loading, id]);

  const onItemPress = useCallback(
    async (item: ActionableListItem) => {
      if (allowClick.current) {
        allowClick.current = false;
        const buyer = await DataStore.query(Buyer, id);
        const product = await DataStore.query(Product, item.id as string);

        if (buyer && product) {
          DataStore.save(
            new ProductOwnership({
              buyer,
              product,
              owner: buyer.owner,
            })
          )
            .then((data) => {
              navigation.navigate(buyerRoutes.buyerProducts, { id });
            })
            .catch((e) => {})
            .finally(() => {
              allowClick.current = true;
            });
        }

        allowClick.current = true;
      }
    },
    [id, navigation]
  );

  return (
    <ActionableList
      loading={loading}
      items={productItems}
      onItemPress={onItemPress}
    />
  );
}
