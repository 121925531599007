import React, { useCallback, useRef } from "react";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import {
  StackHeaderLeftButtonProps,
  HeaderBackButton,
} from "@react-navigation/stack";
import { BackHandler, NativeEventSubscription } from "react-native";

export default function useCustomBack() {
  const navigation = useNavigation();
  const handler = useRef<NativeEventSubscription>();

  useFocusEffect(
    useCallback(() => {
      return () => {
        handler.current?.remove();
      };
    }, [handler])
  );

  return useCallback(
    (callback: () => any) => {
      handler.current = BackHandler.addEventListener(
        "hardwareBackPress",
        () => {
          callback();
          return true;
        }
      );

      navigation.setOptions({
        headerLeft: (props: StackHeaderLeftButtonProps) => (
          <HeaderBackButton {...props} onPress={callback} />
        ),
      });
    },
    [navigation]
  );
}
