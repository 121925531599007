import React, { useState, useEffect, Fragment, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import {
  TextInput,
  FileUploadInput,
  UploadProgress,
  InitialProgress,
  Scaffold,
} from "components";
import { IProgress } from "components/UploadProgress/UploadProgress";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer } from "models";
import { getValuesWithUploadMechanism } from "./utils";
import fixNull from "lib/fix-null";

export default function ManufacturerIspectionDocument() {
  const navigation = useNavigation();
  const customBack = useCustomBack();
  const [progress, setProgress] = useState<IProgress>(InitialProgress);

  useFocusEffect(
    useCallback(() => {
      return () => setProgress(InitialProgress);
    }, [])
  );
  const id = useNavigationParam("id");
  const qaInspectionDocLinkParam = useNavigationParam("qaInspectionDocLink");

  return (
    <Fragment>
      <Scaffold bottom>
        <Formik
          initialValues={{
            qaInspectionDocLink: fixNull(qaInspectionDocLinkParam),
          }}
          validationSchema={validatorSchema}
          onSubmit={async (values) => {
            const manufacturer = await DataStore.query(Manufacturer, id);

            const [, update] = await getValuesWithUploadMechanism(
              manufacturer,
              values,
              ["qaInspectionDocLink"],
              (progress: IProgress) => {
                setProgress(progress);
              }
            );

            update(navigation);
          }}
        >
          {({ submitForm }) => {
            customBack(submitForm);
            return (
              <>
                <FileUploadInput name="qaInspectionDocLink" />
                <SubmitErrorHandler />
              </>
            );
          }}
        </Formik>
      </Scaffold>
      <UploadProgress progress={progress} />
    </Fragment>
  );
}

const validatorSchema = yup.object().shape({
  qaInspectionDocLink: yup.string().required("Field is required"),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    backgroundColor: "white",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    textAlign: "center",
    maxWidth: "100%",
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
  },
  pickerButton: {
    paddingVertical: 30,
  },
});
