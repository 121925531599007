import { AmplifyTheme } from "aws-amplify-react-native";
import theme, { colors, fontFamily, units } from "theme/index";

type Theme = typeof AmplifyTheme;

export const customAwsTheme: Theme = {
  ...AmplifyTheme,
  navButton: {
    ...theme.Button.buttonStyle,
    ...theme.Button.style,
  },
  sectionFooterLink: {
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    color: colors.grey0,
    textAlign: "center",
  },
  inputLabel: {
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    color: colors.black,
    marginBottom: units.nudge * 2,
  },
  input: {
    ...theme.Input.inputContainerStyle,
    ...theme.Input.inputStyle,
  },
  formField: {
    marginBottom: units.nudge * 4,
  },
  pickerItem: {
    ...theme.Input.labelStyle,
  },
  phoneInput: {
    ...theme.Input.inputContainerStyle,
    ...theme.Input.inputStyle,
  },
  errorRowText: {
    color: colors.red,
    fontFamily: fontFamily.primary,
    marginTop: 3,
  },
  sectionHeaderText: {
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    textAlign: "center",
    fontSize: 20,
    color: colors.black,
  },
  button: {
    ...theme.Button.buttonStyle,
    ...theme.Button.style,
  },
  buttonDisabled: {
    ...theme.Button.buttonStyle,
    ...theme.Button.style,
    ...theme.Button.disabledStyle,
  },
  buttonText: { ...theme.Button.titleStyle },
};
