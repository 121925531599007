import React, { useState, useEffect, Fragment, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import {
  TextInput,
  FileUploadInput,
  UploadProgress,
  InitialProgress,
  SecondaryButton,
  Scaffold,
} from "components";
import { IProgress } from "components/UploadProgress/UploadProgress";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer } from "models";
import { getValuesWithUploadMechanism } from "./utils";
import fixNull from "lib/fix-null";

export default function ManufacturerISOLicense() {
  const navigation = useNavigation();
  const customBack = useCustomBack();
  const [progress, setProgress] = useState<IProgress>(InitialProgress);

  useFocusEffect(
    useCallback(() => {
      return () => setProgress(InitialProgress);
    }, [])
  );
  const id = useNavigationParam("id");
  const isoNumberParam = useNavigationParam("isoNumber") || "";
  const isoDocLinkParam = useNavigationParam("isoDocLink");

  return (
    <Fragment>
      <Scaffold bottom>
        <Formik
          initialValues={{
            isoNumber: fixNull(isoNumberParam),
            isoDocLink: fixNull(isoDocLinkParam),
            hasNoIso: null,
          }}
          validationSchema={validatorSchema}
          onSubmit={async (values) => {
            const manufacturer = await DataStore.query(Manufacturer, id);

            const [, update] = await getValuesWithUploadMechanism(
              manufacturer,
              values.hasNoIso
                ? { isoDocLink: null, isoNumber: null, hasNoIso: true }
                : values,
              ["isoDocLink"],
              (progress: IProgress) => {
                setProgress(progress);
              }
            );

            update(navigation);
          }}
        >
          {({ submitForm, setFieldValue }) => {
            customBack(submitForm);
            return (
              <>
                <TextInput
                  name="isoNumber"
                  multiline={false}
                  placeholder="License Number"
                />
                <FileUploadInput name="isoDocLink" />
                <SecondaryButton
                  title="Not Licensed"
                  containerStyle={styles.pickerButton}
                  onPress={() => {
                    setFieldValue("hasNoIso", true);
                    submitForm();
                  }}
                />
                <SubmitErrorHandler />
              </>
            );
          }}
        </Formik>
      </Scaffold>
      <UploadProgress progress={progress} />
    </Fragment>
  );
}

const validatorSchema = yup.object().shape({
  isoNumber: yup.string().nullable(),
  isoDocLink: yup.string().nullable(),
  hasNoIso: yup.boolean().nullable(),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    backgroundColor: "white",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    textAlign: "center",
    maxWidth: "100%",
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
  },
  pickerButton: {
    paddingVertical: 30,
  },
});
