import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
} from "react";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { DataStore } from "aws-amplify";
import useNavigationParam from "lib/use-navigation-param";
import { PurchaseOrder, ProductOwnership } from "models";
import { Product } from "models";
import { useNavigation } from "@react-navigation/native";
import { purchaseOrderRoutes } from "lib/routing/routes";
import useLoadDocuments from "lib/use-load-documents";
import ActionableList from "components/actionable-list/ActionableList";

export default function AssociateProductsWithPurchaseOrder() {
  const allowClick = useRef(true);
  const id = useNavigationParam("id");
  const navigation = useNavigation();
  const [productItems, setProductItems] = useState<ActionableListItem[]>([]);

  const [isLoadingProductOwnership, productOwnership] = useLoadDocuments(
    ProductOwnership
  );

  const [isLoadingProducts, products] = useLoadDocuments(Product, (c) =>
    c.isPublished("eq", true)
  );

  const loading = isLoadingProductOwnership || isLoadingProducts;

  useEffect(() => {
    const newProducts = products.filter(
      (p) =>
        !productOwnership.find(
          (ownership) =>
            ownership.product?.id === p.id && ownership.purchaseOrder?.id === id
        )
    );
    setProductItems(
      newProducts.map((product) => ({
        id: product.id,
        name: product.name,
        subtitle: product.manufacturer?.name,
        route: "",
        hasIndicator: false,
        hasChevron: true,
      }))
    );
  }, [productOwnership, products, id]);

  const onItemPress = useCallback(
    async (item: ActionableListItem) => {
      if (allowClick.current) {
        allowClick.current = false;
        const purchaseOrder = await DataStore.query(PurchaseOrder, id);
        const product = await DataStore.query(Product, item.id as string);

        if (purchaseOrder && product) {
          DataStore.save(
            new ProductOwnership({
              purchaseOrder,
              product,
              owner: purchaseOrder.owner,
            })
          )
            .then((data) => {
              navigation.navigate(purchaseOrderRoutes.purchaseOrderProducts, {
                id,
              });
            })
            .catch((e) => {})
            .finally(() => {
              allowClick.current = true;
            });
        }

        allowClick.current = true;
      }
    },
    [id, navigation]
  );

  return (
    <ActionableList
      loading={loading}
      items={productItems}
      onItemPress={onItemPress}
    />
  );
}
