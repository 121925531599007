import { I18n } from "aws-amplify";
import dictionary from "../../i18n";
import { useEffect } from "react";

export function useI18n() {
  useEffect(() => {
    I18n.putVocabularies(dictionary);
  }, []);

  return dictionary.en;
}
