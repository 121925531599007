import React, { FunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import { units } from "theme";

interface Props {
  size?: number;
}

const Space: FunctionComponent<Props> = ({ size = 1, children }) => {
  const style = StyleSheet.create({
    container: {
      height: size * units.nudge,
    },
  });
  return <View style={style.container}>{children}</View>;
};

export default Space;
