import React, { FunctionComponent, useMemo, useState, useEffect } from "react";

import { Product } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import {
  homeRoutes,
  manufacturerRoutes,
  productRoutes,
} from "lib/routing/routes";
import { useNavigation } from "@react-navigation/native";
import { mapProductsToUI } from "screens/AddProduct/AddProduct.utils";
import useNavigationParam from "lib/use-navigation-param";
import Space from "components/Space/Space";
import ManufacturerProductsList from "screens/Manufacturers/ManufacturerProducts/ManufacturerProductsList";
import ManufacturerProductsActionBar from "screens/Manufacturers/ManufacturerProducts/ManufacturerProductsActionBar";
import useLoadDocuments from "lib/use-load-documents";

const ManufacturerProducts: FunctionComponent = () => {
  const manufacturerId = useNavigationParam("id");
  const [loading, products] = useLoadDocuments(Product, undefined, (items) =>
    items.filter((c) => c.manufacturer?.id === manufacturerId)
  );

  const navigation = useNavigation();

  const onItemPress = (item: ActionableListItem) => {
    if (item.isPublished) {
      return navigation.navigate(productRoutes.productDetails, {
        id: item.id,
      });
    }

    return navigation.navigate(manufacturerRoutes.editProduct, {
      id: item.id,
      manufacturerId,
    });
  };

  const goToManufacturerDetails = () =>
    navigation.navigate(manufacturerRoutes.manufacturerDetails, {
      id: manufacturerId,
    });

  const goToAddProduct = () =>
    navigation.navigate(manufacturerRoutes.addProduct, {
      manufacturerId,
    });

  const productItems = useMemo(() => mapProductsToUI(products), [products]);

  return useMemo(
    () => (
      <>
        <Space size={1} />
        <ManufacturerProductsActionBar onPress={goToAddProduct} />
        <Space size={2} />
        <ManufacturerProductsList
          error={false}
          loading={loading}
          onItemPress={onItemPress}
          items={productItems}
          onPublishPress={goToManufacturerDetails}
        />
      </>
    ),
    [productItems, loading]
  );
};

export default ManufacturerProducts;
