import React from "react";
import { View, StyleSheet } from "react-native";
import { TextInput, Scaffold } from "components";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation } from "@react-navigation/native";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer } from "models";
import { updateManufacturer } from "./utils";
import fixNull from "lib/fix-null";

export default function ManufacturerAddress() {
  const navigation = useNavigation();
  const idParam = useNavigationParam("id");
  const addressParam = useNavigationParam("address") || "";
  const customBack = useCustomBack();

  return (
    <Scaffold>
      <Formik
        initialValues={{ address: fixNull(addressParam) }}
        validationSchema={validatorSchema}
        onSubmit={async (values) => {
          const manufacturer = await DataStore.query(Manufacturer, idParam);
          updateManufacturer(manufacturer, values, navigation);
        }}
      >
        {({ submitForm }) => {
          customBack(submitForm);
          return (
            <>
              <TextInput
                name="address"
                placeholder="Enter the address of the manufacturer"
              />
              <SubmitErrorHandler />
            </>
          );
        }}
      </Formik>
    </Scaffold>
  );
}

const validatorSchema = yup.object().shape({
  address: yup.string().required("Field is required"),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    paddingHorizontal: 40,
    backgroundColor: "white",
  },
});
