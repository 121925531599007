import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback,
} from "react";
import {
  IProgress,
  InitialProgress,
} from "components/UploadProgress/UploadProgress";
import { UploadProgress } from "components";

export default function useUploadProgress(): [
  JSX.Element,
  Dispatch<SetStateAction<IProgress>>
] {
  const [progress, setProgress] = useState<IProgress>(InitialProgress);

  return [<UploadProgress progress={progress} />, setProgress];
}
