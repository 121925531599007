import React from "react";
import { View, StyleSheet } from "react-native";
import { units } from "theme";

interface Props {
  children?: React.ReactNode;
  bottom?: boolean;
}

const Scaffold = ({ children, bottom = false }: Props) => {
  return (
    <View
      style={StyleSheet.flatten([
        styles.container,
        bottom ? { paddingBottom: 40 } : {},
      ])}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
    paddingTop: 40,
    paddingHorizontal: 40,
    backgroundColor: "white",
  },
});

export default Scaffold;
