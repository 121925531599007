import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Buyer } from "models";
import { first } from "lodash";
import { PurchaseOrderList } from "screens";
import { useNavigation } from "@react-navigation/native";
import { StackHeaderLeftButtonProps } from "@react-navigation/stack";
import { Text } from "react-native-elements";
import { StyleSheet } from "react-native";
import { homeRoutes } from "lib/routing/routes";
import { I18n } from "aws-amplify";
import { useI18n } from "hooks/use-i18n";
import { fontFamily, colors } from "theme";
import { Scaffold } from "components";
import { Loading } from "components/actionable-list/ActionableList.style";

export default function BuyerHome() {
  const i18nKeys = useI18n();
  const [buyer, setBuyer] = useState<Buyer>();
  const navigation = useNavigation();

  useEffect(() => {
    if (buyer) {
      navigation.setOptions({
        headerRight: (props: StackHeaderLeftButtonProps) => (
          <Text
            style={styles.link}
            onPress={() => navigation.navigate(homeRoutes.logout)}
          >
            {I18n.get(i18nKeys["Sign Out"])}
          </Text>
        ),
      });
    }
  }, [buyer]);

  useEffect(() => {
    async function init() {
      const newBuyer = first(await DataStore.query(Buyer));
      setBuyer(newBuyer);
    }

    let subscription: any = null;

    if (!buyer) {
      init();
      subscription = DataStore.observe(Buyer).subscribe(() => init());
    }

    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [buyer?.id]);

  if (!buyer) {
    return (
      <Scaffold>
        <Loading />
      </Scaffold>
    );
  }

  return <PurchaseOrderList ID={buyer.id} />;
}

const styles = StyleSheet.create({
  link: {
    fontSize: 14,
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    color: colors.grey0,
  },
});
