import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import useNavigationParam from "lib/use-navigation-param";
import useLoadDocuments, { useLoadDocument } from "lib/use-load-documents";
import { ProductOwnership, PurchaseOrder } from "models";
import { ActionableHeader } from "components";
import { manufacturerRoutes, purchaseOrderRoutes } from "lib/routing/routes";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import ActionableList from "components/actionable-list/ActionableList";
import { sortBy } from "lodash";
import useSubscription from "lib/use-subscription";
import { Auth } from "aws-amplify";

export default function PurchaseOrderProductsList() {
  useSubscription(ProductOwnership);
  const id = useNavigationParam("id") as string;
  const navigation = useNavigation();
  const [purchaseOrdersUI, setPurchaseOrdersUI] = useState<
    ActionableListItem[]
  >([]);
  const [isLoading, purchaseOrder] = useLoadDocument(PurchaseOrder, id);
  const [isLoadingProductOwnership, productOwnerships] = useLoadDocuments(
    ProductOwnership
  );
  const hideAdd = useNavigationParam("hideAdd");

  const loading = isLoading || isLoadingProductOwnership;

  useEffect(() => {
    const newProducts = productOwnerships.filter(
      (po) => po.purchaseOrder?.id === id
    );

    setPurchaseOrdersUI(
      sortBy(newProducts, (o) => o?.product?.name).map((o) => ({
        id: o.id,
        name: o.product!.name,
        hasChevron: false,
        hasIndicator: false,
        isPublished: true,
        route: manufacturerRoutes.manufacturerProducts,
      }))
    );
  }, [purchaseOrder, productOwnerships]);

  const onItemPress = async (item: ActionableListItem) => {
    // const session = await Auth.currentSession();
    // const groups = session.getAccessToken().payload["cognito:groups"];
  };

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({ title: purchaseOrder?.poId || "" });
    }, [navigation, purchaseOrder])
  );

  const onPress = useCallback(() => {
    navigation.navigate(purchaseOrderRoutes.associatePurchaseOrderProduct, {
      id,
    });
  }, [id, navigation]);

  return (
    <View style={styles.container}>
      <ActionableHeader
        onPress={!hideAdd ? onPress : undefined}
        title="Products"
      />
      <ActionableList
        items={purchaseOrdersUI}
        loading={loading}
        onItemPress={onItemPress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
