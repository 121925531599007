import "react-native-gesture-handler";

import React, { useEffect, RefObject, useState } from "react";
import { useFonts } from "@use-expo/font";
import { AppLoading } from "expo";
import {
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import useUpdateCheck from "hooks/use-update-check";
import { useI18n } from "hooks/use-i18n";
import { createStackNavigator } from "@react-navigation/stack";
import { DataStore } from "@aws-amplify/datastore";
import {
  withAuthenticator,
  Loading,
  ConfirmSignIn,
  VerifyContact,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
} from "aws-amplify-react-native";
import Analytics from "@aws-amplify/analytics";

import CustomSignIn from "components/auth/SignInForm";
import Greetings from "components/auth/Greeting";
import { Manufacturer } from "models";
import {
  HomeScreen,
  BuyerProducts,
  BuyersList,
  ManufacturersList,
  AddManufacturer,
  ManufacturerName,
  ManufacturerAddress,
  ManufacturerBusinessLicense,
  ManufacturerFDALicense,
  ManufacturerCELicense,
  ManufacturerDescription,
  ManufacturerISOLicense,
  ManufacturerIspectionDocument,
  ProductNioshLicense,
  SignOut,
  ManufacturerProducts,
  ProductName,
  ProductDescription,
  ProductCECertification,
  ProducFDACertification,
  AssociateBuyerProduct,
  ProductDetails,
  AddInspectionReport,
  ManufacturerDetails,
  AddPurchaseOrder,
  PurchaseOrderNumber,
  PurchaseOrderManufacturerNumber,
  PurchaseOrderDateOfShipment,
  PurchaseOrderProductsList,
  AssociateProductsWithPurchaseOrder,
  PurchaseOrderDetails,
  BuyerHome,
} from "screens";

import { allow } from "components/auth/utils";

import {
  homeRoutes,
  manufacturerRoutes,
  productRoutes,
  buyerRoutes,
  purchaseOrderRoutes,
} from "lib/routing/routes";
import { useRouting } from "lib/routing/use-routing";
import { HeaderRightButton } from "./src/components";
import { ThemeProvider } from "react-native-elements";

import Amplify, { Auth, Hub, I18n } from "aws-amplify";
import config from "./aws-exports";
import AddProduct from "screens/AddProduct/AddProduct";
import theme, { mainHeaderOptions } from "theme";
import CreateUser from "screens/Auth/CreateUser";
import { get, includes } from "lodash";
import { customAwsTheme } from "theme/custom-aws-login-theme";
import { BuyerProductsWithoutAdd } from "screens/Buyers/BuyerProducts";
import PurcheseOrderList from "screens/PurchaseOrder/PurchaseOrderList";

Amplify.configure(config);
Analytics.configure({ disabled: true });
// https://github.com/aws-amplify/amplify-js/issues/5448
// This can be removed once version is available via NPM
// Sync datastore every minute fully
DataStore.configure({ ...config, ...{ fullSyncInterval: 10 } });

const Stack = createStackNavigator();

interface IProps {
  onStateChange(eventName: string, comp: any): void;
}

function Routes({ ...props }: IProps) {
  useUpdateCheck();
  const i18nKeys = useI18n();
  const { isReady, initialState, navigationRef } = useRouting();

  // TODO: extract as hook
  const [currentUserGroups, setCurrentUserGroups] = useState<string[]>([]);

  useEffect(() => {
    async function init() {
      const session = await Auth.currentSession();
      setCurrentUserGroups(session.getAccessToken().payload["cognito:groups"]);
    }
    //We listen to the signout event, and force the login screen to be displayed
    Hub.listen("auth", (authData) => {
      if (authData.payload.event === "signOut") {
        DataStore.clear().then(() => {
          props.onStateChange("signedOut", null);
        });
      }
    });
    init();

    // We kick of a subscription for the datastore to start syncing with the backend.
    const subscription = DataStore.observe(Manufacturer).subscribe((msg) => {});
    return () => subscription.unsubscribe();
  }, []);

  if (!isReady || currentUserGroups.length == 0) {
    return <AppLoading />;
  }

  return (
    <NavigationContainer
      ref={navigationRef as RefObject<NavigationContainerRef>}
      initialState={initialState}
    >
      <Stack.Navigator
        initialRouteName={homeRoutes.home}
        screenOptions={mainHeaderOptions}
      >
        {allow(["brokerteam", "broker"], currentUserGroups) && (
          <Stack.Screen
            name={homeRoutes.home}
            component={HomeScreen}
            options={{
              title: I18n.get(i18nKeys["What would you like to do?"]),
            }}
          />
        )}

        {allow(["brokerteam", "broker"], currentUserGroups) && (
          <>
            <Stack.Screen
              name={homeRoutes.addManufacturer}
              component={AddManufacturer}
              options={{ title: I18n.get(i18nKeys["Add Manufacturer"]) }}
            />
            <Stack.Screen
              name={homeRoutes.editManufacturer}
              component={AddManufacturer}
              options={{ title: I18n.get(i18nKeys["Edit Manufacturer"]) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerName}
              component={ManufacturerName}
              options={{ title: I18n.get(i18nKeys.Name) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerAddress}
              component={ManufacturerAddress}
              options={{ title: I18n.get(i18nKeys.Address) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerDescription}
              component={ManufacturerDescription}
              options={{ title: I18n.get(i18nKeys.Description) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerBusinessLicenseUpload}
              component={ManufacturerBusinessLicense}
              options={{ title: I18n.get(i18nKeys["Business License Upload"]) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerCELicenseUpload}
              component={ManufacturerCELicense}
              options={{ title: I18n.get(i18nKeys["CE License Upload"]) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerFDALicenseUpload}
              component={ManufacturerFDALicense}
              options={{ title: I18n.get(i18nKeys["FDA License Upload"]) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerISOLicenseUpload}
              component={ManufacturerISOLicense}
              options={{ title: I18n.get(i18nKeys["ISO License Upload"]) }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturerIspectionDocument}
              component={ManufacturerIspectionDocument}
              options={{
                title: I18n.get(i18nKeys["Inspection Document Upload"]),
              }}
            />
            <Stack.Screen
              name={productRoutes.productNioshCertification}
              component={ProductNioshLicense}
              options={{ title: I18n.get(i18nKeys["Niosh Document Upload"]) }}
            />
            <Stack.Screen
              name={manufacturerRoutes.addProduct}
              component={AddProduct}
              options={{ title: I18n.get(i18nKeys["Add Product"]) }}
            />
            <Stack.Screen
              name={manufacturerRoutes.editProduct}
              component={AddProduct}
              options={{ title: I18n.get(i18nKeys["Edit Product"]) }}
            />
            <Stack.Screen
              name={productRoutes.productName}
              component={ProductName}
              options={{ title: I18n.get(i18nKeys.Name) }}
            />
            <Stack.Screen
              name={productRoutes.productDescription}
              component={ProductDescription}
              options={{ title: I18n.get(i18nKeys.Description) }}
            />
            <Stack.Screen
              name={manufacturerRoutes.manufacturerProducts}
              component={ManufacturerProducts}
              options={({ route }) => ({ title: get(route, "params.name") })}
            />
            <Stack.Screen name={homeRoutes.buyers} component={BuyersList} />
            <Stack.Screen
              name={buyerRoutes.buyerProducts}
              component={BuyerProducts}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={buyerRoutes.associateBuyerProduct}
              component={AssociateBuyerProduct}
              options={{ title: "Available Products" }}
            />
            <Stack.Screen
              name={homeRoutes.manufacturers}
              component={ManufacturersList}
              options={({ navigation }) => ({
                headerRight: () => (
                  <HeaderRightButton navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name={productRoutes.productCeCertificationUpload}
              component={ProductCECertification}
              options={{ title: "CE Certification Upload" }}
            />
            <Stack.Screen
              name={productRoutes.productFdaCertificationUpload}
              component={ProducFDACertification}
              options={{ title: "FDA Certification Upload" }}
            />
            <Stack.Screen
              name={productRoutes.productDetails}
              component={ProductDetails}
              options={{ title: "Product Details" }}
            />
            <Stack.Screen
              name={manufacturerRoutes.manufacturerDetails}
              component={ManufacturerDetails}
              options={{ title: "Manufacturer Details" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.addPurchaseOrder}
              component={AddPurchaseOrder}
              options={{ title: "Add Purchase Order" }}
            />
            <Stack.Screen
              name={buyerRoutes.editPurchaseOrder}
              component={AddPurchaseOrder}
              options={{ title: "Edit Purchase Order" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.purchaseOrderNumber}
              component={PurchaseOrderNumber}
              options={{ title: "Purchase Order Number" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.purchaseOrderManufacturerNumber}
              component={PurchaseOrderManufacturerNumber}
              options={{ title: "Purchase Order Manufacturer Number" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.purchaseOrderShippingDate}
              component={PurchaseOrderDateOfShipment}
              options={{ title: "Purchase Order Shipment" }}
            />
            <Stack.Screen
              name={buyerRoutes.buyersListForOrders}
              component={BuyersList}
              initialParams={{ routeToGo: buyerRoutes.buyerPurchaseOrders }}
              options={{ title: "Buyer List" }}
            />
            <Stack.Screen
              name={buyerRoutes.buyerPurchaseOrders}
              component={PurcheseOrderList}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.globalPurchaseOrderList}
              initialParams={{ allPOs: true }}
              component={PurcheseOrderList}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.purchaseOrderProducts}
              component={PurchaseOrderProductsList}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.associatePurchaseOrderProduct}
              component={AssociateProductsWithPurchaseOrder}
              options={{ title: "Available Products" }}
            />
          </>
        )}
        {allow(["inspector", "broker", "brokerteam"], currentUserGroups) && (
          <>
            <Stack.Screen
              name={
                allow(["broker", "brokerteam"], currentUserGroups)
                  ? homeRoutes.addInspectionReport
                  : homeRoutes.home
              }
              component={AddInspectionReport}
              options={{ title: I18n.get(i18nKeys["Add Inspection Report"]) }}
            />
          </>
        )}
        {allow(["buyer"], currentUserGroups) && (
          <>
            <Stack.Screen
              name={homeRoutes.home}
              component={BuyerHome}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={buyerRoutes.buyerProducts}
              component={BuyerProductsWithoutAdd}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={productRoutes.productDetails}
              component={ProductDetails}
              options={{ title: "Product Details" }}
            />
            <Stack.Screen
              name={buyerRoutes.buyerPurchaseOrdersWithoutAdd}
              initialParams={{ hideAdd: true, hideBack: true }}
              component={PurcheseOrderList}
              options={{ title: "" }}
            />
            <Stack.Screen
              name={purchaseOrderRoutes.purchaseOrderDetails}
              component={PurchaseOrderDetails}
              options={{ title: "" }}
            />
          </>
        )}
        {allow(["broker"], currentUserGroups) && (
          <>
            <Stack.Screen
              name={homeRoutes.createUser}
              component={CreateUser}
              options={{ title: I18n.get(i18nKeys["Create A New User"]) }}
            />
          </>
        )}
        <Stack.Screen
          name={homeRoutes.logout}
          component={SignOut}
          options={{ title: I18n.get(i18nKeys["Sign Out"]) }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const authComponents = [
  <Loading />,
  // @ts-ignore
  <CustomSignIn hideSignUp />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ConfirmSignUp />,
  <ForgotPassword />,
  <RequireNewPassword />,
  <Greetings />,
];

const App = withAuthenticator(
  Routes,
  false,
  authComponents as any,
  null,
  customAwsTheme
);

export default () => {
  // TODO: create init hook tasked with loading all dependencies required for rendering the App
  //load fonts

  useI18n();

  const [fontsLoaded] = useFonts({
    "Asap-Medium": require("./assets/fonts/Asap-Medium.ttf"),
    "Quicksand-Bold": require("./assets/fonts/Quicksand-Bold.ttf"),
  });

  if (!fontsLoaded) {
    return (
      <ThemeProvider theme={theme}>
        <AppLoading />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  );
};
