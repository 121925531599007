import {
  PersistentModelConstructor,
  DataStore,
  ProducerModelPredicate,
} from "@aws-amplify/datastore";
import { useState, useCallback, useRef, useMemo } from "react";
import { sortBy, get } from "lodash/fp";
import { useFocusEffect } from "@react-navigation/native";

export function useLoadDocument<
  T extends Readonly<
    {
      id: string;
    } & Record<string, any>
  >
>(model: PersistentModelConstructor<T>, id: string): [boolean, T | undefined] {
  const [document, setDocument] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const hasData = useRef(false);

  const callback = useCallback(() => {
    async function load(loading = true) {
      if (loading) setIsLoading(true);
      const documents = await DataStore.query(model, id);
      hasData.current = !!documents;
      setDocument(documents);
      setIsLoading(false);
    }

    load();

    const subscription = DataStore.observe(model).subscribe((msg) => {
      load(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useFocusEffect(callback);

  return [isLoading, document];
}

export default function useLoadDocuments<
  T extends Readonly<
    {
      id: string;
    } & Record<string, any>
  >
>(
  model: PersistentModelConstructor<T>,
  criteria?: ProducerModelPredicate<T> | undefined,
  filter: (items: T[]) => T[] = (elements) => elements
): [boolean, T[]] {
  const [documents, setDocuments] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const hasData = useRef(false);

  const callback = useCallback(() => {
    async function load() {
      if (!hasData.current) setIsLoading(true);
      const documents = await DataStore.query(model, criteria);
      hasData.current = documents.length > 0;
      setDocuments(filter(documents));
      setIsLoading(false);
    }

    load();
    const subscription = DataStore.observe(model).subscribe((msg) => {
      load();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useFocusEffect(callback);

  const orderedDocuments = useMemo(
    (): T[] => sortBy((c) => get("name", c) || get("id", c))(documents) as T[],
    [documents]
  );

  return [isLoading, orderedDocuments];
}
