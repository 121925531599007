import { Storage, Auth } from "aws-amplify";
import { split } from "lodash";

interface IPutItem {
  level: "public" | "private" | "protected";
  identityId?: string;
  progressCallback?(progress: any): void;
  [key: string]: any;
}

export async function PutItem(key: string, object: any, options: IPutItem) {
  const credentials = await Auth.currentCredentials();
  return await Storage.put(`${credentials.identityId}/${key}`, object, options);
}

export async function GetItem(key: string, object: IPutItem) {
  const [identityId] = split(key, "/");
  return await Storage.get(key, { ...object, identityId });
}
