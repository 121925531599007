import React from "react";
import { StyleSheet } from "react-native";
import { Input } from "react-native-elements";
import { useField, FieldHookConfig } from "formik";

type Props = FieldHookConfig<any> & {
  multiline?: boolean;
  placeholder?: string;
  onChangeText?(text: string): void;
  onFocus?(): void;
};

export default function TextInput({
  multiline = true,
  placeholder,
  onFocus,
  onChangeText,
  ...props
}: Props) {
  const [{ value }, meta, { setValue }] = useField(props);
  return (
    <Input
      onFocus={() => onFocus && onFocus()}
      value={value}
      onChangeText={(e) => {
        setValue(e);
        if (onChangeText) onChangeText(e);
      }}
      errorMessage={meta.touched && meta.error ? meta.error : ""}
      multiline={multiline}
      underlineColorAndroid="transparent"
      placeholder={placeholder}
      inputStyle={StyleSheet.flatten([{ minHeight: multiline ? 120 : 50 }])}
    />
  );
}
