import { useState, useEffect, useCallback, useRef } from "react";
import { useFormikContext } from "formik";
import { useNavigation } from "@react-navigation/native";
import alert from "./alert";
import { homeRoutes } from "./routing/routes";

export default function SubmitErrorHandler(): null {
  const navigation = useNavigation();
  const isShowing = useRef(false);

  const displayError = useCallback(() => {
    setTimeout(() => {
      if (!isShowing.current) {
        isShowing.current = true;
        alert({
          title: "Do you want to leave?",
          message: "One of the fields are not currently fill",
          buttons: [
            {
              text: "Leave Screen",
              onPress: () => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                } else {
                  return navigation.reset({
                    index: 1,
                    routes: [{ name: homeRoutes.home }],
                  });
                }
                isShowing.current = false;
              },
            },
            {
              text: "Stay",
              onPress: () => {
                isShowing.current = false;
              },
            },
          ],
        });
      }

      isShowing.current = false;
    }, 250);
  }, []);

  const { submitCount, isValid } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      displayError();
      setLastHandled(submitCount);
    }
  }, [submitCount, isValid, displayError, lastHandled]);

  return null;
}
