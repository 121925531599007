import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
  useRef,
} from "react";
import { useField, useFormikContext } from "formik";
import { Button, Text } from "react-native-elements";
import {
  ImageBackground,
  StyleSheet,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import { startsWith, get, StringIterator } from "lodash";
import * as DocumentPicker from "expo-document-picker";
import * as mimeType from "react-native-mime-types";
import { Storage } from "aws-amplify";
import { GetItem } from "lib/Storage";

interface Props {
  name: string;
}

function handleChange(
  event: React.ChangeEvent<HTMLInputElement>
): Promise<DocumentPicker.DocumentResult> {
  return new Promise((resolve, reject) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) =>
        resolve({
          lastModified: file.lastModified,
          uri: reader.result as string,
          name: file.name,
          size: file.size,
          type: "success",
        });
    } else {
      resolve({ type: "cancel" });
    }
  });
}

export function getCurrentImageFieldName(field: string) {
  return `pre__${field}`;
}

export default function FileUploadInput({ name }: Props) {
  const [field, _, { setValue }] = useField({ name });
  const { values, setValues } = useFormikContext();
  const [rightURI, setRightURI] = useState<string | Object>();
  const [isLoadingURI, setIsLoadingURI] = useState(false);
  const webPicker = useRef<HTMLInputElement>(null);

  const currentImageFieldName = getCurrentImageFieldName(name);
  const currentImage: DocumentPicker.DocumentResult = get(
    values,
    currentImageFieldName
  );

  useEffect(() => {
    async function getPreview() {
      if (!currentImage && field.value) {
        setIsLoadingURI(true);
        const url = await GetItem(field.value, {
          level: "protected",
        });

        if (startsWith(mimeType.lookup(field.value), "image")) setRightURI(url);
      }
    }

    getPreview();
  }, [JSON.stringify(currentImage), setValues]);

  const renderName = useMemo((): string | null => {
    if (
      !currentImage &&
      field.value &&
      !startsWith(mimeType.lookup(field.value), "image")
    ) {
      return field.value as string;
    }

    if (
      currentImage &&
      currentImage.type == "success" &&
      !startsWith(mimeType.lookup(currentImage.name), "image")
    ) {
      return currentImage.name;
    }

    return null;
  }, [JSON.stringify(currentImage), field.value]);

  const openPicker = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      async function getImage() {
        let result = event
          ? await handleChange(event)
          : await DocumentPicker.getDocumentAsync({});

        if (result.type == "success") {
          setValues({
            ...(values as {}),
            [currentImageFieldName]: result,
          });
          setValue(result.uri);
          setRightURI(result.uri);
        }
      }

      getImage();
    },
    [setValues, currentImageFieldName, JSON.stringify(values)]
  );

  const openWebPicker = useCallback(() => {
    webPicker.current?.click();
  }, [webPicker]);

  return (
    <>
      {Platform.OS === "web" ? (
        <Fragment>
          <Button
            title="Select Document"
            containerStyle={styles.pickerButton}
            onPress={openWebPicker}
          />
          <input
            type="file"
            ref={webPicker}
            style={{ display: "none" }}
            onChange={openPicker}
          />
        </Fragment>
      ) : (
        <Button
          title="Select Document"
          onPress={() => openPicker()}
          containerStyle={styles.pickerButton}
        ></Button>
      )}

      <ImageBackground
        source={{ uri: typeof rightURI === "string" ? rightURI : undefined }}
        resizeMode="cover"
        style={styles.backgroundImage}
        onLoadEnd={() => setIsLoadingURI(false)}
      >
        {renderName ? (
          <View style={styles.textContainer}>
            <Text style={styles.title}>{renderName}</Text>
          </View>
        ) : null}

        {isLoadingURI && !renderName ? (
          <View style={styles.textContainer}>
            <ActivityIndicator size="large" />
          </View>
        ) : null}
      </ImageBackground>
    </>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    textAlign: "center",
    maxWidth: "100%",
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
  },
  pickerButton: {
    paddingVertical: 30,
  },
});
