import React, {
  useState,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { View, StyleSheet } from "react-native";
import {
  TextInput,
  FileUploadInput,
  UploadProgress,
  InitialProgress,
} from "components";
import { IProgress } from "components/UploadProgress/UploadProgress";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { DataStore } from "@aws-amplify/datastore";
import { InspectionReport, PurchaseOrder, ProductOwnership } from "models";
import { Button, Text } from "react-native-elements";
import { getValuesWithUploadMechanism } from "./utils";
import { Auth } from "aws-amplify";
import { get, noop, startsWith } from "lodash";
import { homeRoutes } from "lib/routing/routes";
import useSubscription from "lib/use-subscription";
import { units, fontFamily, colors } from "theme";
import { I18n } from "aws-amplify";
import { useI18n } from "hooks/use-i18n";
import ActionableList from "components/actionable-list/ActionableList";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import moment from "moment";

export default function AddInspectionReport() {
  const navigation = useNavigation();
  const i18nKeys = useI18n();
  const [selectedPO, setSelectedPO] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [progress, setProgress] = useState<IProgress>(InitialProgress);
  const [count, setCount] = useState(0);

  useSubscription(InspectionReport);
  useSubscription(PurchaseOrder);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Text
          style={styles.link}
          onPress={() => navigation.navigate(homeRoutes.logout)}
        >
          {I18n.get(i18nKeys["Sign Out"])}
        </Text>
      ),
    });
  }, []);

  const searchPurchaseOrders = useCallback(
    async (text: string) => {
      if (purchaseOrders.length === 0) setLoading(true);

      const items = await DataStore.query(PurchaseOrder);
      const newItems = items.filter((item) =>
        startsWith(item.poId.toLowerCase(), text.toLowerCase())
      );

      setPurchaseOrders(newItems);
      setLoading(false);
    },
    [purchaseOrders]
  );

  const options: ActionableListItem[] = useMemo(
    () =>
      purchaseOrders.map((po) => ({
        id: po.id,
        name: po.poId,
        subtitle: po.buyer?.name,
        route: "",
        hasChevron: true,
        hasIndicator: false,
      })),
    [purchaseOrders]
  );

  const onItemPress = useCallback(
    (item: ActionableListItem, setValue: (text: string) => void) => {
      setPurchaseOrders([]);
      setSelectedPO(item.id);
      setValue(item.name);
    },
    []
  );

  return (
    <Fragment>
      <View style={styles.container}>
        <Formik
          initialValues={{
            purchaseOrderNumber: "",
            docLink: null,
          }}
          validationSchema={validatorSchema}
          onSubmit={async (values, { resetForm, setFieldValue }) => {
            const params = await getValuesWithUploadMechanism(
              values,
              ["docLink"],
              (progress: IProgress) => {
                setProgress(progress);
              }
            );

            const session = await Auth.currentSession();
            const purchaseOrder = await DataStore.query(
              PurchaseOrder,
              selectedPO!
            );

            const date = moment().toISOString();

            await DataStore.save(
              PurchaseOrder.copyOf(purchaseOrder, (updated) => {
                updated.inspectionReport = new InspectionReport({
                  docLink: get(params, "docLink") as string,
                  purchaseOrder,
                  owner: [
                    purchaseOrder.owner!,
                    session.getAccessToken().payload["sub"],
                  ],
                  createdAt: date,
                  updatedAt: date,
                });
              })
            );

            resetForm();
            setCount(count + 1);
            setSelectedPO(undefined);
            setPurchaseOrders([]);

            return navigation.navigate(homeRoutes.home);
          }}
        >
          {({ submitForm, isValid, setFieldValue, values }) => {
            return (
              <>
                <TextInput
                  name="purchaseOrderNumber"
                  multiline={false}
                  placeholder="PO Number"
                  onFocus={() => {
                    setSelectedPO(undefined);
                    setFieldValue("purchaseOrderNumber", "");
                  }}
                  onChangeText={searchPurchaseOrders}
                />
                {!selectedPO && values.purchaseOrderNumber ? (
                  <ActionableList
                    items={options}
                    loading={loading}
                    onItemPress={(item) => {
                      onItemPress(item, (text) =>
                        setFieldValue("purchaseOrderNumber", text)
                      );
                    }}
                  />
                ) : null}
                <FileUploadInput key={count.toString()} name="docLink" />
                <View style={styles.buttonContainer}>
                  <Button
                    onPress={submitForm}
                    disabled={!isValid || !selectedPO}
                    style={styles.pickerButton}
                    title="Publish"
                  />
                </View>
              </>
            );
          }}
        </Formik>
      </View>
      <UploadProgress progress={progress} />
    </Fragment>
  );
}

const validatorSchema = yup.object().shape({
  purchaseOrderNumber: yup
    .string()
    .required("Field is required")
    .min(1, "Field is invalid"),
  docLink: yup.string().required("Field is required"),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    backgroundColor: "white",
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
  },
  link: {
    fontSize: 14,
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    color: colors.grey0,
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    textAlign: "center",
    maxWidth: "100%",
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
  },
  pickerButton: {
    paddingVertical: 20,
  },
  buttonContainer: {
    alignItems: "center",
  },
});
