/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hxssa44tpzhrfgeu523a3kt3fq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:2af6b643-61e1-4152-92fd-ca7193dbc4bd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IazXxotKU",
    "aws_user_pools_web_client_id": "3hh6727q20o2ejh3i6rq4jesd0",
    "oauth": {},
    "aws_user_files_s3_bucket": "hatcheryscmdocuments102237-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
