import React from "react";
import { Text, TextProps } from "react-native-elements";
import { StyleSheet } from "react-native";
import { colors } from "theme";

export default function AddButton(props: TextProps): JSX.Element {
  return (
    <Text onPress={props.onPress} style={styles.addButton}>
      +
    </Text>
  );
}

const styles = StyleSheet.create({
  addButton: {
    fontSize: 34,
    color: colors.red,
  },
});
