import { Product } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { isEmpty, isNull, get } from "lodash";
import { productRoutes } from "lib/routing/routes";

export const mapProductsToUI = (p: Product[]): ActionableListItem[] =>
  p.map((p) => ({
    name: p.name,
    id: p.id,
    disabled: false,
    hasChevron: true,
    hasIndicator: true,
    isPublished: !!p.isPublished,
    // TODO: find out if `${productRoutes.productDetails}/${p.id}` works
    route: productRoutes.productDetails,
  }));

export const validateFdaDoc = (m: Product) =>
  hasFdaDoc(m) || (hasCeDoc(m) ? undefined : false);

export const validateCeDoc = (m: Product) =>
  hasCeDoc(m) || (hasFdaDoc(m) ? undefined : false);

const hasFdaDoc = (m: Product) =>
  !isEmpty(m.fdaDocLink) && !isEmpty(m.fdaNumber);

const hasCeDoc = (m: Product) => !isEmpty(m.ceDocLink) && !isEmpty(m.ceNumber);

export const determineProductIsPublished = (p: Product) =>
  !isEmpty(p.id) &&
  !isEmpty(p.name) &&
  !isEmpty(p.description) &&
  (validateFdaDoc(p) || validateCeDoc(p));

export const mapProductToUI = (p: Product): ActionableListItem[] => [
  {
    id: "Name",
    name: "Name",
    route: productRoutes.productName,
    isPublished: !isEmpty(p.name),
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Description",
    name: "Description",
    route: productRoutes.productDescription,
    isPublished: !isEmpty(p.description),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.name),
  },
  {
    id: "CE Certification Upload",
    name: "CE Certification Upload",
    route: productRoutes.productCeCertificationUpload,
    isPublished: validateCeDoc(p),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.name),
  },
  {
    id: "FDA Certification Upload",
    name: "FDA Certification Upload",
    route: productRoutes.productFdaCertificationUpload,
    isPublished: validateFdaDoc(p),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.name),
  },

  {
    id: "Niosh Document",
    name: "Niosh Document",
    route: productRoutes.productNioshCertification,
    hasChevron: true,
    isPublished:
      !get(p, "hasNoNiosh") && get(p, "nioshNumber") && get(p, "nioshDocLink")
        ? true
        : undefined,
    hasIndicator: true,
    disabled: isEmpty(p.name),
  },
];

export const initialValues: ActionableListItem[] = [
  {
    id: "Name",
    name: "Name",
    route: productRoutes.productName,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Description",
    name: "Description",
    route: productRoutes.productDescription,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "CE Certification Upload",
    name: "CE Certification Upload",
    route: productRoutes.productCeCertificationUpload,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "FDA Certification Upload",
    name: "FDA Certification Upload",
    route: productRoutes.productCeCertificationValidation,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Niosh Document",
    name: "Niosh Document",
    route: productRoutes.productNioshCertification,
    hasChevron: true,
    isPublished: undefined,
    hasIndicator: true,
    disabled: true,
  },
];
