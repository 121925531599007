import React, { useLayoutEffect, useEffect, useCallback, useMemo } from "react";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import { View, Text, StyleSheet } from "react-native";
import useNavigationParam from "lib/use-navigation-param";
import useLoadDocuments, { useLoadDocument } from "lib/use-load-documents";
import { Buyer, ProductOwnership, Manufacturer, PurchaseOrder } from "models";
import { ActionableHeader } from "components";
import {
  buyerRoutes,
  homeRoutes,
  productRoutes,
  manufacturerRoutes,
  purchaseOrderRoutes,
} from "lib/routing/routes";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import ActionableList from "components/actionable-list/ActionableList";
import { sortBy, includes } from "lodash";
import useSubscription from "lib/use-subscription";
import { Auth } from "aws-amplify";

interface Props {
  ID?: string;
}

export default function PurcheseOrderList({ ID }: Props) {
  useSubscription(ProductOwnership);
  const navigation = useNavigation();
  const id = (useNavigationParam("id") as string) || (ID as string);
  const hideAdd = useNavigationParam("hideAdd");
  const allPOs = useNavigationParam<boolean>("allPOs");
  const [isLoading, buyer] = useLoadDocument(Buyer, id);

  const [purchaseOrdersLoading, allPurchaseOrders] = allPOs
    ? useLoadDocuments(PurchaseOrder)
    : useLoadDocuments(PurchaseOrder, undefined, (orders) => {
        const data = orders.filter((order) => {
          return order.buyer?.id === id;
        });
        return data;
      });

  const onItemPress = async (item: ActionableListItem) => {
    const session = await Auth.currentSession();
    const groups = session.getAccessToken().payload["cognito:groups"];

    if (includes(groups, "buyer")) {
      return navigation.navigate(purchaseOrderRoutes.purchaseOrderDetails, {
        id: item.id,
      });
    }

    const po = allPurchaseOrders.find((x) => x.id == item.id);
    const buyerId = po && po.buyer ? po.buyer?.id : id;
    return navigation.navigate(buyerRoutes.editPurchaseOrder, {
      buyerId: buyerId,
      id: item.id,
      allPOs,
    });
  };

  const ownership = useMemo(
    () => allPurchaseOrders.filter((c) => c?.buyer?.id === id),
    [id, allPurchaseOrders]
  );

  const ownershipUI = useMemo(
    (): ActionableListItem[] =>
      sortBy(allPOs ? allPurchaseOrders : ownership, (o) => o?.updatedAt).map(
        (o) => ({
          id: o.id,
          name: o.poId,
          hasChevron: false,
          hasIndicator: false,
          isPublished: true,
          subtitle: allPOs ? o.buyer?.name : undefined,
          route: manufacturerRoutes.manufacturerProducts,
        })
      ),
    [ownership]
  );

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({ title: buyer?.name || "" });
    }, [navigation, buyer])
  );

  const onPress = useCallback(() => {
    navigation.navigate(purchaseOrderRoutes.addPurchaseOrder, { buyerId: id });
  }, [id, navigation]);

  return (
    <View style={styles.container}>
      <ActionableHeader
        onPress={!hideAdd && !allPOs && !ID ? onPress : undefined}
        title="Purchase Orders"
      />
      <ActionableList
        items={ownershipUI}
        loading={purchaseOrdersLoading}
        onItemPress={onItemPress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
