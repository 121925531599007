import React from "react";

import { Theme } from "react-native-elements";
import {
  StackNavigationOptions,
  StackHeaderLeftButtonProps,
  Header,
  HeaderBackButton,
  StackHeaderProps,
} from "@react-navigation/stack";
import { NavigationProp, RouteProp } from "@react-navigation/native";
import { homeRoutes, buyerRoutes } from "lib/routing/routes";
import { Platform, View } from "react-native";
import { useDimensions } from "@react-native-community/hooks";

export const units = {
  radius: 8,
  nudge: 8,
  lg: 1024,
};

export const fontFamily = {
  primary: "Asap-Medium",
  secondary: "Quicksand-Bold",
};

export const colors = {
  black: "#354249",
  grey0: "#ABB5BE",
  grey1: "#EAECEE",
  white: "#FFFFFF",
  red: "#F85F6A",
  green: "#7FE1AD",
};

export function mainHeaderOptions({
  route,
  navigation,
}: {
  route: RouteProp<Record<string, object | undefined>, string>;
  navigation: NavigationProp<any>;
}): StackNavigationOptions {
  return {
    headerBackTitleVisible: false,
    animationEnabled: true,
    headerTitleContainerStyle: {
      width: "75%",
      alignItems: "center",
    },
    headerLeftContainerStyle: {
      paddingLeft: 8,
    },
    headerRightContainerStyle: {
      paddingRight: 8,
    },
    headerStyle: {
      minHeight: units.nudge * 10,
      borderBottomWidth: 0,
    },
    headerTitleStyle: {
      fontFamily: fontFamily.primary,
      fontSize: 18,
      color: colors.black,
    },
    headerTitleAlign: "center",
    headerBackTitleStyle: {
      color: colors.black,
    },
    headerTintColor: colors.red,
    cardStyle: {
      backgroundColor: "white",
    },
    header: HeaderCustom,
    headerLeft:
      route.name === homeRoutes.home
        ? undefined
        : (props: StackHeaderLeftButtonProps) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                if (navigation.canGoBack()) {
                  return navigation.goBack();
                }

                return navigation.reset({
                  index: 1,
                  routes: [{ name: homeRoutes.home }],
                });
              }}
            />
          ),
  };
}

function HeaderCustom(props: StackHeaderProps) {
  const { width } = useDimensions().window;
  const cWidth = width - units.lg;

  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: cWidth > 0 ? cWidth / 2 : 0,
      }}
    >
      <Header {...props} />
    </View>
  );
}

const theme: Theme = {
  colors: {
    success: colors.green,
    warning: colors.black,
    primary: colors.red,
    secondary: colors.black,
    error: colors.red,
    grey0: colors.grey0,
  },
  Text: {
    style: {
      fontFamily: fontFamily.primary,
      fontWeight: "600",
    },
    h1Style: {
      fontFamily: fontFamily.secondary,
    },
    h2Style: {
      fontFamily: fontFamily.secondary,
    },
    h3Style: {
      fontFamily: fontFamily.secondary,
    },
    h4Style: {
      fontFamily: fontFamily.secondary,
    },
  },
  Input: {
    inputContainerStyle: {
      borderColor: colors.grey0,
      borderRadius: 0,
      borderWidth: 1,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
    labelStyle: {
      fontFamily: fontFamily.primary,
      color: colors.black,
    },
    placeholderTextColor: colors.grey0,
    inputStyle: {
      padding: units.nudge * 1.5,
      fontFamily: fontFamily.primary,
      fontSize: 16,
    },
  },
  ListItem: {
    titleProps: { ellipsizeMode: "tail", numberOfLines: 1 },
    titleStyle: {
      fontFamily: fontFamily.primary,
      fontSize: 16,
      color: colors.black,
    },
    topDivider: false,
    containerStyle: {
      borderTopWidth: 0,
    },
    disabledStyle: { opacity: 0.45 },
  },
  Button: {
    buttonStyle: {
      width: "100%",
      backgroundColor: colors.red,
      paddingVertical: 12,
      borderRadius: units.radius,
    },
    containerStyle: {
      maxWidth: 320,
      width: "100%",
      borderRadius: units.radius,
    },
    disabledStyle: {
      backgroundColor: colors.grey1,
    },
    style: {
      shadowColor: colors.black,
      shadowOpacity: 0.3,
      shadowOffset: { height: 2, width: 0 },
      shadowRadius: 16,
      elevation: 1,
      borderRadius: units.radius,
    },
    titleStyle: {
      fontWeight: "bold",
      color: colors.white,
      fontFamily: fontFamily.primary,
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
};
export default theme;
