import React, { FunctionComponent } from "react";
import { Button, ButtonProps } from "react-native-elements";
import { StyleSheet, View } from "react-native";

const floatingButtonStyle = StyleSheet.create({
  container: {
    padding: 16,
    width: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    marginBottom: 6,
  },
});
const FloatingButton: FunctionComponent<ButtonProps> = (props) => (
  <View style={floatingButtonStyle.container}>
    <Button {...props} />
  </View>
);
export default FloatingButton;
