import { Buyer } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { buyerRoutes } from "lib/routing/routes";

export const mapBuyersToUI = (buyers: Buyer[]): ActionableListItem[] =>
  buyers.map((m) => ({
    id: m.id,
    name: m.name,
    hasChevron: false,
    hasIndicator: false,
    isPublished: true,
    route: buyerRoutes.buyerProducts,
  }));
