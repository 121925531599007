import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-elements";
import Constants from "expo-constants";
import { Greetings } from "aws-amplify-react-native";
import { colors, fontFamily, units } from "theme";

export default class CustomGreetings extends Greetings {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.small}>{Constants.manifest.version}</Text>
      </View>
    );
  }
}

export const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  small: {
    color: colors.grey0,
    fontFamily: fontFamily.secondary,
    fontSize: 11,
    padding: units.nudge * 2,
  },
});
