export const homeRoutes = {
  home: "Home",
  manufacturers: "Manufacturers",
  manufacturer: "Manufacturer",
  addManufacturer: "AddManufacturer",
  editManufacturer: "EditManufacturer",
  addInspectionReport: "AddInspectionReport",
  buyers: "Buyers",
  manufacturerName: "ManufacturerName",
  manufacturerAddress: "ManufacturerAddress",
  manufacturerDescription: "ManufacturerDescription",
  manufacturerBusinessLicenseUpload: "ManufacturerBusinessLicenseUpload",
  manufacturerCELicenseUpload: "ManufacturerCELicenseUpload",
  manufacturerFDALicenseUpload: "ManufacturerFDALicenseUpload",
  manufacturerISOLicenseUpload: "manufacturerISOLicenseUpload",
  manufacturerIspectionDocument: "ManufacturerIspectionDocument",
  manufacturerNioshLicense: "ManufacturerNioshLicense",
  createUser: "CreateUser",
  logout: "LogOut",
  inspectionReport: "Inspection Report",
};

export const manufacturerRoutes = {
  manufacturerProducts: "ManufacturerProducts",
  addProduct: "AddProduct",
  editProduct: "EditProduct",
  manufacturerDetails: "ManufacturerDetails",
};

export const productRoutes = {
  productDetails: "productDetails",
  productName: "productName",
  productAddress: "productAddress",
  productDescription: "productDescription",
  productCeCertificationUpload: "productCeCertificationUpload",
  productCeCertificationValidation: "productCeCertificationValidation",
  productFdaCertificationUpload: "productFdaCertificationUpload",
  productFdaCertificationValidation: "productFdaCertificationValidation",
  productNioshCertification: "productNioshCertification",
};

export const purchaseOrderRoutes = {
  globalPurchaseOrderList: "globalPurchaseOrderList",
  addPurchaseOrder: "AddPurchaseOrder",
  purchaseOrderDetails: "purchaseOrderDetails",
  purchaseOrderNumber: "purchaseOrderNumber",
  purchaseOrderManufacturerNumber: "purchaseOrderManufacturerNumber",
  purchaseOrderShippingDate: "purchaseOrderShippingDate",
  purchaseOrderInspectionReport: "purchaseOrderInspectionReport",
  purchaseOrderProducts: "PurchaseOrderProducts",
  associatePurchaseOrderProduct: "AssociatePurchaseOrderProduct",
};

export const buyerRoutes = {
  buyerHome: "BuyerHome",
  buyerProducts: "BuyerProducts",
  buyersListForOrders: "BuyersListForOrders",
  editPurchaseOrder: "editPurchaseOrder",
  buyerPurchaseOrders: "BuyerPurchaseOrders",
  buyerPurchaseOrdersWithoutAdd: "BuyerPurchaseOrdersWithoutAdd",
  associateBuyerProduct: "AssociateBuyerProduct",
};
