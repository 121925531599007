import React from "react";
import { Button, ButtonProps } from "react-native-elements";
import { StyleSheet } from "react-native";
import theme, { colors, mainHeaderOptions } from "theme";

export default function SecondaryButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      buttonStyle={StyleSheet.flatten([
        theme.Button?.buttonStyle,
        styles.button,
      ])}
      titleStyle={StyleSheet.flatten([theme.Button?.titleStyle, styles.title])}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.black,
  },
  title: {
    color: colors.red,
  },
});
