import React, { FunctionComponent, useCallback } from "react";
import { Manufacturer } from "models";
import FloatingButton from "components/buttons/FloatingButton";
import { useNavigation } from "@react-navigation/native";
import { mapManufacturerToUI } from "./AddManufacturer.utils";
import ActionableList from "components/actionable-list/ActionableList";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { determineManufacturerIsPublished } from "screens/Manufacturers/ManufacturersList.utils";

/*

User goes to screen

All inputs are disabled but name

After a name is saved all inputs are enabled

Publish can only be pressed if all fields are filled

 */

interface Props {
  manufacturer: Manufacturer;
  onSubmit: (manufacturer: Manufacturer) => Promise<void>;
}

const AddManufacturerForm: FunctionComponent<Props> = ({
  manufacturer,
  onSubmit,
}) => {
  const fields = mapManufacturerToUI(manufacturer);

  const navigation = useNavigation();

  const onItemPress = useCallback(
    (item: ActionableListItem) => {
      navigation.navigate(item.route as string, manufacturer);
    },
    [navigation, manufacturer]
  );

  return (
    <>
      <ActionableList
        items={fields}
        onItemPress={onItemPress}
        loading={false}
      />
      <FloatingButton
        title="Publish"
        loading={false}
        disabled={!determineManufacturerIsPublished(manufacturer)}
        onPress={() => onSubmit(manufacturer)}
      />
    </>
  );
};

export default AddManufacturerForm;
