import React, { useState, useCallback, useRef } from "react";
import { View, Keyboard, TouchableOpacity } from "react-native";
import { Input } from "react-native-elements";
import DateModal from "components/date-modal/DateModal";
import moment from "moment";
import { omit } from "lodash";

import { Props } from "./Input.types";
import { useField } from "formik";

const DateInput = React.forwardRef(
  (props: Props<number>, ref: React.Ref<Input>) => {
    const [visible, setVisible] = useState(false);
    const allowCancel = useRef(false);
    const { dateProps, omitDatePicker, yearMask } = props;
    const [{ value }, meta, { setValue }] = useField({ name: props.name });

    const handleCancel = useCallback(() => {
      if (allowCancel.current) setVisible(false);
    }, []);

    const setDate = useCallback((date: Date) => {
      setValue(moment(date).toISOString());
      setVisible(false);
    }, []);

    const openPicker = useCallback(() => {
      allowCancel.current = false;
      setVisible(true);
      setTimeout(() => {
        allowCancel.current = true;
      }, 1000);
    }, []);

    const formatedValue = value
      ? omit(
          {
            month: moment(value, moment.ISO_8601).format("MM"),
            date: moment(value, moment.ISO_8601).format("DD"),
            year: moment(value, moment.ISO_8601).format(yearMask || "YYYY"),
          },
          omitDatePicker || []
        )
      : null;

    return (
      <>
        <TouchableOpacity onPress={openPicker}>
          <View pointerEvents="none">
            <Input
              ref={ref}
              {...props}
              onChangeText={undefined}
              value={
                formatedValue
                  ? `${Object.values(formatedValue).join(" / ")}`
                  : ""
              }
            />
          </View>
        </TouchableOpacity>
        <DateModal
          isVisible={visible}
          dateProps={dateProps}
          onCancel={handleCancel}
          onConfirm={setDate}
          omitDatePicker={omitDatePicker}
        />
      </>
    );
  }
);

DateInput.displayName = "DateInput";

export default DateInput;
