import React, { FunctionComponent, useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Buyer, Product, PurchaseOrder } from "models";
import { DataStore } from "@aws-amplify/datastore";

import AddPurchaseOrderForm from "screens/PurchaseOrder/AddPurchaseOrderForm";
import useNavigationParam from "lib/use-navigation-param";
import alert from "lib/alert";
import { useNavigation } from "@react-navigation/native";
import { get } from "lodash";
import useHandleBack from "lib/use-handle-back";
import {
  buyerRoutes,
  homeRoutes,
  purchaseOrderRoutes,
} from "lib/routing/routes";
import { useLoadDocument } from "lib/use-load-documents";

const AddPurchaseOrder: FunctionComponent = () => {
  const id = useNavigationParam("id");
  const buyerId = useNavigationParam("buyerId");
  const allPOs = useNavigationParam<boolean>("allPOs");
  const navigation = useNavigation();
  const [loading, purchaseOrder] = useLoadDocument(PurchaseOrder, id);

  useHandleBack(async () => {
    if (allPOs) {
      navigation.navigate(purchaseOrderRoutes.globalPurchaseOrderList);
    } else {
      navigation.navigate(buyerRoutes.buyerPurchaseOrders, {
        id: buyerId,
        name:
          purchaseOrder?.buyer?.name ||
          (await DataStore.query(Buyer, buyerId)).name,
      });
    }
  }, [navigation, purchaseOrder, allPOs]);

  useFocusEffect(
    useCallback(() => {
      if (allPOs) {
        DataStore.query(Buyer, buyerId).then((buyer) => {
          navigation.setOptions({ title: buyer?.name || "" });
        });
      }
    }, [navigation, allPOs])
  );

  const publishPurchaseOrder = async (purchaseOrder: PurchaseOrder) => {
    try {
      const currentBuyer = await DataStore.query(Buyer, buyerId);

      await DataStore.save(
        PurchaseOrder.copyOf(purchaseOrder, (updated) => {
          updated.buyer = currentBuyer;
        })
      );

      navigation.navigate(buyerRoutes.buyerPurchaseOrders, {
        id: currentBuyer.id,
        name: currentBuyer.name,
      });
    } catch (e) {
      alert({ message: "Error saving purchase order.", title: "Error" });
      console.error(e.message, { buyerId });
    }
  };

  return (
    <AddPurchaseOrderForm
      buyerId={buyerId}
      purchaseOrder={purchaseOrder}
      onSubmit={publishPurchaseOrder}
      loading={loading}
    />
  );
};
export default AddPurchaseOrder;
