import React, { FunctionComponent } from "react";
import { FlatList, StyleSheet } from "react-native";
import ListItem from "./list-item/ListItem";
import { Empty, Footer, Loading } from "./ActionableList.style";
import { ActionableListItem } from "./ActionableList.types";
import { units } from "theme";

interface Props {
  items: ActionableListItem[];
  loading: boolean;
  onItemPress: (item: ActionableListItem) => void;
  noFooter?: boolean;
}

const ActionableList: FunctionComponent<Props> = ({
  items,
  loading,
  onItemPress,
  noFooter = false,
}) => {
  if (loading) return <Loading />;
  if (items.length === 0) return <Empty />;

  return (
    <FlatList
      contentContainerStyle={styles.contentContainerStyle}
      keyExtractor={(item) => item.id as string}
      data={items}
      renderItem={({ item }) => (
        <ListItem item={item} onItemPress={onItemPress} />
      )}
      ListFooterComponent={noFooter ? undefined : <Footer />}
    />
  );
};

const styles = StyleSheet.create({
  container: {},
  contentContainerStyle: {
    flexGrow: 1,
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
  },
});

export default ActionableList;
