import React, { useCallback, useState, useMemo } from "react";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { DataStore } from "@aws-amplify/datastore";
import { Buyer } from "models";
import { sortBy } from "lodash";
import ActionableList from "components/actionable-list/ActionableList";
import { mapBuyersToUI } from "./BuyersList.utils";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import useNavigationParam from "lib/use-navigation-param";

export default function BuyersList() {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const [buyers, setBuyers] = useState<Buyer[]>([]);
  const routeToGo = useNavigationParam("routeToGo");
  const [actionableList, setActionableList] = useState<ActionableListItem[]>(
    []
  );

  const fetchManufacturers = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log("fetching");
      const manufacturers = await DataStore.query(Buyer);
      const sortedByName = sortBy(manufacturers, (m) => m.name);
      setIsLoading(false);
      setBuyers(sortedByName);
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetchManufacturers();
      const subscription = DataStore.observe(Buyer).subscribe((msg) => {
        console.log("subscribe", msg);
        fetchManufacturers();
      });

      return () => {
        console.log("unsubscribe");
        return subscription.unsubscribe();
      };
    }, [])
  );

  useMemo(() => {
    setIsLoading(true);
    async function loadItems() {
      const result = await mapBuyersToUI(buyers);
      setIsLoading(false);
      setActionableList(result);
    }

    loadItems();
  }, [buyers]);

  const onItemPress = useCallback(
    (item: ActionableListItem) => {
      navigation.navigate(routeToGo || item.route, { id: item.id });
    },
    [navigation]
  );

  return (
    <ActionableList
      items={actionableList}
      loading={isLoading}
      onItemPress={onItemPress}
    />
  );
}
