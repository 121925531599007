import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useFocusEffect } from "@react-navigation/native";
import AddManufacturerForm from "screens/Manufacturers/AddManufacturer/AddManufacturerForm";
import { Manufacturer } from "models";
import { DataStore } from "@aws-amplify/datastore";

import { useRoute, useNavigation } from "@react-navigation/native";
import { get } from "lodash";
import useHandleBack from "lib/use-handle-back";
import { homeRoutes } from "lib/routing/routes";
import { ActivityIndicator } from "react-native";
import { Center } from "components/utils/Center";

interface Props {
  name: string;
}

const AddManufacturer: FunctionComponent<Props> = () => {
  const navigation = useNavigation();
  const manufacturerParam = get(useRoute(), "params", {}) as Manufacturer;
  const [manufacturer, setManufacturer] = useState<Manufacturer>(
    manufacturerParam
  );
  const [loaded, setLoaded] = useState(!manufacturer.id);

  useHandleBack(() => navigation.navigate(homeRoutes.manufacturers), [
    navigation,
  ]);

  useFocusEffect(
    useCallback(() => {
      if (manufacturer.id) {
        DataStore.query(Manufacturer, manufacturer.id).then((manufacturer) => {
          setManufacturer(manufacturer);
          setLoaded(true);
        });

        const subscription = DataStore.observe(
          Manufacturer,
          manufacturer?.id
        ).subscribe((msg) => {
          setManufacturer(msg.element);
        });

        return () => {
          subscription.unsubscribe();
        };
      }
    }, [manufacturer?.id])
  );

  const publishManufacturer = async (manufacturer: Manufacturer) => {
    await DataStore.save(
      Manufacturer.copyOf(manufacturer, (updated) => {
        updated.isPublished = true;
      })
    );
    navigation.navigate(homeRoutes.manufacturers);
  };

  if (!loaded) {
    return (
      <Center>
        <ActivityIndicator size="large" />
      </Center>
    );
  }

  return (
    <AddManufacturerForm
      onSubmit={publishManufacturer}
      manufacturer={manufacturer}
    />
  );
};

export default AddManufacturer;
