/* global __DEV__ */
import { useEffect } from "react";
import { Platform, AppState } from "react-native";
import * as Updates from "expo-updates";
import { DataStore } from "@aws-amplify/datastore";

import alert from "lib/alert";

function checkIOSPWAUpdate(): void {
  // Workaround for forcing updates of installed PWA on iOS.
  // Based on solution provided here: https://gist.github.com/kawazoe/fa3b5a3c998d16871ffb9e2fd721cb4b
  const r = new XMLHttpRequest();
  r.onload = () => {
    const t = r.responseText;
    const matches = /index.html",revision:"([a-z0-9]{32})"},/g.exec(t);
    if (matches?.length == 2) {
      const ls = localStorage;
      const oldPrecacheManifestVersion = ls.getItem("pmv");
      const newPrecacheManifestVersion = matches[1];

      // No version find (First app execution), persist current version to local storage
      if (!oldPrecacheManifestVersion) {
        ls.setItem("pmv", newPrecacheManifestVersion);
        return;
      }

      // Existing version found in local storage and differs from latest version, prompt for update
      if (newPrecacheManifestVersion !== oldPrecacheManifestVersion) {
        alert({
          title:
            "Update Available\n\nAn update has been published, would you like to update?",
          buttons: [
            {
              onPress(): void {
                ls.setItem("pmv", newPrecacheManifestVersion);
                DataStore.clear();

                caches.keys().then(async function (names) {
                  await Promise.all(names.map((name) => caches.delete(name)));
                });

                window.location.reload(true)
              },
            },
          ],
        });
      }
    }
  };
  const dateStr = new Date().getTime();
  r.open("GET", `/service-worker.js?c=${dateStr}`);
  r.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
  r.send();
}

async function checkUpdates(): Promise<void> {
  try {
    if (!__DEV__ && Platform.OS !== "web") {
      const { isAvailable } = await Updates.checkForUpdateAsync();

      if (isAvailable) {
        alert({
          title: "Update Available",
          message: "An update has been published, would you like to update?",
          buttons: [
            {
              text: "No",
              onPress(): void {},
            },
            {
              text: "Yes",
              async onPress(): Promise<void> {
                await Updates.fetchUpdateAsync();
                Updates.reloadAsync();
              },
            },
          ],
          options: {
            cancelable: false,
          },
        });
      }
    } else if (Platform.OS === "web") {
      // Workaround for iOS installed PWA update, to avoid app getting stuck on single version
      if (
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1
      ) {
        checkIOSPWAUpdate();
      }
    }
  } catch (e) {}
}

export default (): void => {
  useEffect(() => {
    checkUpdates();

    AppState.addEventListener("change", checkUpdates);

    return (): void => AppState.removeEventListener("change", checkUpdates);
  }, []);
};
