import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { homeRoutes } from "../../lib/routing/routes";

import { Auth } from "aws-amplify";

export default function SignOut() {
  const navigation = useNavigation();
  useEffect(() => {
    Auth.signOut();
    navigation.navigate(homeRoutes.home);
  });

  return (
    <View style={styles.container}>
      <View>
        <Text> SignOut successful</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
