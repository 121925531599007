import React from "react";
import { useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { BackHandler } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  HeaderBackButton,
  StackHeaderLeftButtonProps,
} from "@react-navigation/stack";

export default function useHandleBack(_callback: () => any, deps: any[]) {
  const navigation = useNavigation();
  const callback = useCallback(_callback, deps);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props: StackHeaderLeftButtonProps) => (
        <HeaderBackButton {...props} onPress={callback} />
      ),
    });
  }, [callback]);

  useFocusEffect(
    useCallback(() => {
      const backButtonHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        callback
      );

      return () => {
        backButtonHandler.remove();
      };
    }, [callback])
  );
}
