import React, { useState, useEffect } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { TextInput } from "components";
import { Formik, FieldArray, Field, getIn } from "formik";
import * as yup from "yup";
import { useNavigation, PrivateValueStore } from "@react-navigation/native";
import useNavigationParam from "lib/use-navigation-param";
import { Button, CheckBox } from "react-native-elements";
import Space from "components/Space/Space";
import { Auth } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";
import { Buyer } from "models";
import useSubscription from "lib/use-subscription";
import { units } from "theme";

export default function CreateBuyer() {
  useSubscription(Buyer);
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBuyerSelected, setBuyerSelected] = useState(false);
  interface Role {
    id: string;
    name: string;
  }

  const userRoles = [
    { id: "broker", name: "Broker" },
    { id: "brokerteam", name: "Broker Team Member" },
    { id: "buyer", name: "Buyer" },
    { id: "inspector", name: "Inspector" },
  ] as Role[];

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainerStyle}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
          buyer_name: "",
          buyer_representative: "",
          buyer_phone: "",
          buyer_address: "",
          buyer_website: "",
          userRoles: [] as string[],
        }}
        validationSchema={yup
          .object()
          .shape(isBuyerSelected ? buyerShape : userShape)}
        onSubmit={(values, { resetForm }) => {
          setIsProcessing(true);
          Auth.signUp({
            username: values.email,
            password: values.password,
            attributes: isBuyerSelected
              ? {
                  phone_number: values.buyer_phone,
                  "custom:buyer_name": values.buyer_name,
                  "custom:buyer_representative": values.buyer_name,
                  "custom:buyer_address": values.buyer_address,
                  "custom:buyer_website": values.buyer_website,
                  "custom:user_roles": values.userRoles.join(),
                }
              : {
                  "custom:user_roles": values.userRoles.join(),
                },
          })
            .then((data) => {
              DataStore.save(
                new Buyer({
                  cognitoId: data.userSub,
                  email: values.email,
                  phone: values.buyer_phone,
                  name: values.buyer_name,
                  representative: values.buyer_representative,
                  address: values.buyer_address,
                  website: values.buyer_website,
                  owner: data.userSub,
                })
              );
              resetForm({});
              setMessage("User created sucessfully");
              setIsProcessing(false);
            })
            .catch((err) => {
              setMessage(err.message);
              setIsProcessing(false);
            });
        }}
      >
        {({ submitForm, values, errors, touched }) => {
          return (
            <>
              <TextInput
                value={values.email || ""}
                name="email"
                placeholder="Email address"
                multiline={false}
              />
              <Space size={2} />
              <TextInput
                value={values.password || ""}
                name="password"
                placeholder="Password"
                multiline={false}
              />
              <Space size={2} />
              <FieldArray
                name="userRoles"
                render={(arrayHelpers) =>
                  userRoles.map((role) => (
                    <CheckBox
                      key={role.id}
                      title={role.name}
                      checked={values.userRoles.includes(role.id)}
                      onPress={() => {
                        const roleIdx = values.userRoles.indexOf(role.id);
                        if (roleIdx == -1) {
                          arrayHelpers.push(role.id);
                        } else {
                          arrayHelpers.remove(roleIdx);
                        }

                        if (role.id === "buyer") {
                          if (roleIdx == -1) {
                            setBuyerSelected(true);
                          } else {
                            setBuyerSelected(false);
                          }
                        }
                      }}
                    />
                  ))
                }
              />
              {touched.userRoles && <UserRoleErrors errors={errors} />}
              {isBuyerSelected && (
                <>
                  <TextInput
                    value={values.buyer_name || ""}
                    name="buyer_name"
                    placeholder="Name of buyer"
                    multiline={false}
                  />
                  <Space size={2} />
                  <TextInput
                    value={values.buyer_representative || ""}
                    name="buyer_representative"
                    placeholder="Name of respresentative"
                    multiline={false}
                  />
                  <Space size={2} />
                  <TextInput
                    value={values.buyer_phone || ""}
                    name="buyer_phone"
                    placeholder="Phone Number"
                    multiline={false}
                  />
                  <Space size={2} />
                  <TextInput
                    value={values.buyer_address || ""}
                    name="buyer_address"
                    placeholder="Address"
                  />
                  <Space size={2} />
                  <TextInput
                    value={values.buyer_website || ""}
                    name="buyer_website"
                    placeholder="Website"
                    multiline={false}
                  />
                </>
              )}
              {message.length > 0 && (
                <>
                  <Space size={2} />
                  <Text style={styles.error}>{message}</Text>
                </>
              )}
              <Space size={2} />
              <View style={styles.buttonContainer}>
                <Button
                  title="Create new User"
                  onPress={submitForm}
                  loading={isProcessing}
                  disabled={isProcessing}
                />
              </View>
              <Space size={5} />
            </>
          );
        }}
      </Formik>
    </ScrollView>
  );
}

// within a `FieldArray`'s render
const UserRoleErrors = (errors: any) =>
  typeof errors.errors.userRoles === "string" ? (
    <Text style={styles.error}>{errors.errors.userRoles}</Text>
  ) : null;

const userShape = {
  email: yup
    .string()
    .max(256, "Too Long")
    .email("Invalid Email")
    .required("Field is required"),
  password: yup.string().max(256, "Too Long").required("Field is required"),
  userRoles: yup.array().min(1, "Select at least one role"),
};

const buyerShape = {
  ...userShape,
  ...{
    buyer_name: yup.string().max(256, "Too Long").required("Field is required"),
    buyer_phone: yup
      .string()
      .max(256, "Too Long")
      .required("Field is required"),
    buyer_address: yup
      .string()
      .max(256, "Too Long")
      .required("Field is required"),
    buyer_representative: yup
      .string()
      .max(256, "Too Long")
      .required("Field is required"),
    buyer_website: yup
      .string()
      .max(256, "Too Long")
      .required("Field is required"),
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    paddingHorizontal: 40,
    backgroundColor: "white",
  },
  contentContainerStyle: {
    flexGrow: 1,
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
  },
  error: {
    color: "rgb(248, 95, 106)",
    fontSize: 12,
    margin: 5,
    paddingLeft: 8,
  },
  buttonContainer: {
    alignItems: "center",
  },
});
