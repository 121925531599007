import { Buyer, PurchaseOrder } from "models";
import { DataStore } from "@aws-amplify/datastore";
import { omit, last, get } from "lodash";
import { NavigationProp } from "@react-navigation/native";
import { getCurrentImageFieldName } from "components";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import {
  manufacturerRoutes,
  buyerRoutes,
  purchaseOrderRoutes,
} from "lib/routing/routes";
import { PutItem } from "lib/Storage";

export async function getValuesWithUploadMechanism(
  buyerId: string,
  purchaseOrder: PurchaseOrder,
  values: { [x: string]: any },
  uploadKeys: string[] = [],
  progressCallback?: Function
): Promise<Record<string, any>> {
  let omitPaths: string[] = [];

  for (let index = 0; index < uploadKeys.length; index++) {
    const uploadKey = uploadKeys[index];
    const preKey = getCurrentImageFieldName(uploadKey);
    const preValue = values[preKey];

    if (preValue) {
      const name = `buyer/${buyerId}/purchaseOrders/${
        purchaseOrder.id
      }/${uploadKey}.${last(preValue.name.split("."))}`;

      const response = await fetch(preValue.uri);
      const blob = await response.blob();

      if (progressCallback) progressCallback({ loaded: 0.0001, total: 1 });

      const insertedImage = await PutItem(name, blob, {
        contentType: mimeType.lookup(name),
        level: "protected",
        // @ts-ignore
        progressCallback(progress) {
          if (progressCallback) {
            progressCallback(progress);
          }
        },
      });

      values[uploadKey] = get(insertedImage, "key");
      omitPaths.push(preKey);
    }
  }

  return omit(values, omitPaths);
}

export async function updatePurchaseOrder(
  purchaseOrder: PurchaseOrder,
  values: Record<string, any>,
  buyerId: string,
  navigation?: NavigationProp<any>
): Promise<void> {
  await DataStore.save(
    PurchaseOrder.copyOf(purchaseOrder, (updated) => {
      Object.keys(values).map((key) => {
        // @ts-ignore
        updated[key] = values[key];
      });
    })
  );

  if (navigation) {
    navigation.navigate(buyerRoutes.editPurchaseOrder, {
      ...purchaseOrder,
      buyerId,
    });
  }
}

export async function createOrUpdatePurchaseOrder(
  buyerId: string,
  values: PurchaseOrder | Partial<PurchaseOrder>
): Promise<PurchaseOrder> {
  if (!buyerId) {
    throw new Error("No Buyer");
  }

  const buyer = await DataStore.query(Buyer, buyerId);

  if (values.id) {
    const purchaseOrder = await DataStore.query(PurchaseOrder, values.id);

    return await DataStore.save(
      PurchaseOrder.copyOf(purchaseOrder, (updated) => {
        Object.keys(values).map((key) => {
          // @ts-ignore
          updated[key] = values[key];
        });

        updated.buyer = buyer;
      })
    );
  }

  // Initialize the number of purchase orders
  let numberOfPurchaseOrders = buyer.numberOfPurchaseOrders;
  if (numberOfPurchaseOrders == null || numberOfPurchaseOrders == undefined) {
    numberOfPurchaseOrders = (await DataStore.query(PurchaseOrder)).filter(
      (p) => p.buyer?.id === buyer.id
    ).length;
  }

  const purchaseOrder = await DataStore.save(
    // @ts-ignore
    new PurchaseOrder({
      ...omit(values, ["id"]),
      owner: buyer.owner,
      buyer,
    })
  );

  await DataStore.save(
    Buyer.copyOf(buyer, (updated) => {
      updated.numberOfPurchaseOrders = numberOfPurchaseOrders! + 1;
    })
  );
  return new Promise((resolve) => {
    resolve(purchaseOrder);
  });
}
