import React, { useLayoutEffect, useEffect, useCallback, useMemo } from "react";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import { View, Text, StyleSheet } from "react-native";
import useNavigationParam from "lib/use-navigation-param";
import useLoadDocuments, { useLoadDocument } from "lib/use-load-documents";
import { Buyer, ProductOwnership, Manufacturer, PurchaseOrder } from "models";
import { ActionableHeader } from "components";
import {
  buyerRoutes,
  homeRoutes,
  productRoutes,
  manufacturerRoutes,
} from "lib/routing/routes";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import ActionableList from "components/actionable-list/ActionableList";
import { noop, sortBy, get } from "lodash";
import useSubscription from "lib/use-subscription";

interface Props {
  allowAdd?: boolean;
}

export default function BuyerPurchaseOrders({ allowAdd = true }: Props) {
  useSubscription(ProductOwnership);
  const navigation = useNavigation();
  const id = useNavigationParam("id") as string;
  const [isLoading, buyer] = useLoadDocument(Buyer, id);
  const [purchaseOrdersLoading, allPurchaseOrders] = useLoadDocuments(
    PurchaseOrder
  );

  const onItemPress = (item: ActionableListItem) => {
    if (item.isPublished)
      return navigation.navigate(productRoutes.productDetails, {
        id: item.id,
        name: item.name,
      });

    return navigation.navigate(homeRoutes.editManufacturer, {
      id: item.id,
    });
  };

  const ownership = useMemo(
    () => allPurchaseOrders.filter((c) => c?.buyer?.id === id),
    [id, allPurchaseOrders]
  );

  const ownershipUI = useMemo(
    (): ActionableListItem[] =>
      sortBy(ownership, (o) => o?.poId).map((o) => ({
        id: o.poId,
        name: o.poId,
        hasChevron: false,
        hasIndicator: false,
        isPublished: true,
        route: manufacturerRoutes.manufacturerProducts,
      })),
    [ownership]
  );

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({ title: buyer?.name || "" });
    }, [navigation, buyer])
  );

  const onPress = useCallback(() => {
    navigation.navigate(buyerRoutes.associateBuyerProduct, { id });
  }, [id, navigation]);

  return (
    <View style={styles.container}>
      <ActionableHeader
        onPress={allowAdd ? onPress : undefined}
        title="Products"
      />
      <ActionableList
        items={ownershipUI}
        loading={purchaseOrdersLoading}
        onItemPress={onItemPress}
      />
    </View>
  );
}

export function BuyerProductsWithoutAdd() {
  return <BuyerPurchaseOrders allowAdd={false} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
