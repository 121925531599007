import React, { useMemo } from "react";
import { Manufacturer } from "models";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import { startsWith, endsWith } from "lodash";
import { GetItem } from "lib/Storage";

export interface section {
  title: string;
  disabled?: boolean;
  data: data[];
}

export interface data {
  id: number;
  label?: string;
  value?: string;
  isImage?: boolean;
  isPDF?: boolean;
  isLink?: boolean;
}

export const useSections = (
  businessLicenseURI: string,
  inspectionURI: string,
  nioshURI: string,
  isoURI: string,
  fdaURI: string,
  ceURI: string,
  manufacturer?: Manufacturer
): section[] => {
  return useMemo(
    () =>
      [
        {
          title: "Name",
          data: [
            {
              id: 1,
              label: "",
              value: manufacturer?.name,
            },
          ],
        },
        {
          title: "Address",
          data: [
            {
              id: 2,
              label: "",
              value: manufacturer?.address,
            },
          ],
        },
        {
          title: "Description",
          data: [
            {
              id: 3,
              label: "",
              value: manufacturer?.description,
            },
          ],
        },
        {
          title: "Business License",
          data: [
            {
              id: 4,
              label: "Number",
              value: manufacturer?.businessLicenseNumber,
            },
            {
              id: 5,
              label: "Click to open",
              value: businessLicenseURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.businessLicenseDocLink),
              isImage: isImage(manufacturer?.businessLicenseDocLink),
            },
          ],
        },
        {
          title: "Inspection Document",
          data: [
            {
              id: 6,
              label: "Click to open",
              value: inspectionURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.qaInspectionDocLink),
              isImage: isImage(manufacturer?.qaInspectionDocLink),
            },
          ],
        },
        {
          title: "Niosh Document",
          disabled: manufacturer?.hasNoNiosh,
          data: [
            {
              id: 7,
              label: "Number",
              value: manufacturer?.nioshNumber,
            },
            {
              id: 8,
              label: "Click to open",
              value: nioshURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.nioshDocLink),
              isImage: isImage(manufacturer?.nioshDocLink),
            },
          ],
        },
        {
          title: "ISO Document",
          disabled: manufacturer?.hasNoIso,
          data: [
            {
              id: 7,
              label: "Number",
              value: manufacturer?.isoNumber,
            },
            {
              id: 8,
              label: "Click to open",
              value: isoURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.isoDocLink),
              isImage: isImage(manufacturer?.isoDocLink),
            },
          ],
        },
        {
          title: "FDA Document",
          disabled: manufacturer?.hasNoFda,
          data: [
            {
              id: 7,
              label: "Number",
              value: manufacturer?.fdaNumber,
            },
            {
              id: 8,
              label: "Click to open",
              value: fdaURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.fdaDocLink),
              isImage: isImage(manufacturer?.fdaDocLink),
            },
          ],
        },
        {
          title: "CE Document",
          disabled: manufacturer?.hasNoCe,
          data: [
            {
              id: 7,
              label: "Number",
              value: manufacturer?.ceNumber,
            },
            {
              id: 8,
              label: "Click to open",
              value: ceURI,
              isLink: true,
              isPDF: isPDF(manufacturer?.ceDocLink),
              isImage: isImage(manufacturer?.ceDocLink),
            },
          ],
        },
      ].filter((item) => !item.disabled),
    [
      manufacturer,
      businessLicenseURI,
      inspectionURI,
      nioshURI,
      isoURI,
      fdaURI,
      ceURI,
    ]
  );
};

export async function getPreview(
  setter: React.Dispatch<React.SetStateAction<string>>,
  value?: string,
  owner?: string
): Promise<boolean> {
  if (value) {
    const url = await GetItem(value, {
      level: "protected",
    });

    if (isImage(value) || isPDF(value)) {
      setter(url.toString());
      return new Promise((resolve) => {
        resolve(true);
      });
    }
  }
  return new Promise((resolve) => {
    resolve(false);
  });
}

function isPDF(value?: string): boolean {
  if (!value) return false;
  return endsWith(mimeType.lookup(value), "pdf");
}
function isImage(value?: string): boolean {
  if (!value) return false;
  return startsWith(mimeType.lookup(value), "image");
}
