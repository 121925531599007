import { Product } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { buyerRoutes } from "lib/routing/routes";

export const mapProductsToUI = (products: Product[]): ActionableListItem[] =>
  products.map((m) => ({
    id: m.id,
    name: m.name,
    hasChevron: true,
    hasIndicator: false,
    isPublished: true,
    subtitle: m.manufacturer?.name,
    route: buyerRoutes.buyerProducts,
  }));
