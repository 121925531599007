import { ActivityIndicator, StyleSheet, View } from "react-native";
import React, { FunctionComponent } from "react";
import { Text } from "react-native-elements";
import { Center } from "../utils/Center";
import { units } from "theme";

export const Empty: FunctionComponent = () => (
  <Center>
    <Text>No items</Text>
  </Center>
);

export const Loading: FunctionComponent = () => (
  <Center>
    <ActivityIndicator size="large" />
  </Center>
);

export const Footer: FunctionComponent = () => (
  <View style={actionableListStyle.empty} />
);

export const actionableListStyle = StyleSheet.create({
  empty: {
    height: units.nudge * 11.5,
  },
});
