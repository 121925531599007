const dictionary = {
  en: {
    "Add Manufacturer/Product": "Manage Manufacturer/Product",
    "What would you like to do?": "What would you like to do?",
    "Add Manufacturer": "Add Manufacturer",
    "Edit Manufacturer": "Edit Manufacturer",
    Name: "Name",
    Address: "Address",
    Description: "Description",
    "Business License Upload": "Business License Upload",
    "CE License Upload": "CE License Upload",
    "FDA License Upload": "FDA License Upload",
    "ISO License Upload": "ISO License Upload",
    "Inspection Document Upload": "Inspection Document Upload",
    "Niosh Document Upload": "Niosh Document Upload",
    "Add Inspection Report": "Add Inspection Report",
    "Add Product": "Add Product",
    "Edit Product": "Edit Product",
    "Create A New User": "Create A New User",
    "Sign Out": "Sign Out",
    "Associate Buyer with Product": "Associate Buyer with Product",
    Manufacturers: "Manufacturers",
    ViewProducts: "View Products",
    ManageBuyers: "Manage Buyers",
    ViewPurchaseOrders: "View Purchase Orders",
  },
  cn: {
    "Add Manufacturer/Product": "管理制造商/产品",
    "What would you like to do?": "你想干什么?",
    "Add Manufacturer": "添加制造商",
    "Edit Manufacturer": "编辑制造商",
    Name: "名称",
    Address: "地址",
    Description: "描述",
    "Business License Upload": "营业执照上传",
    "CE License Upload": "CE 许可证上传",
    "FDA License Upload": "FDA 许可证上传",
    "ISO License Upload": "ISO 许可证上传",
    "Inspection Document Upload": "检验文件上传",
    "Niosh Document Upload": "Niosh 许可证上传",
    "Add Inspection Report": "添加检验报告",
    "Add Product": "添加产品",
    "Edit Product": "编辑产品",
    "Create A New User": "创建一个新用户",
    "Sign Out": "登出",
    "Associate Buyer with Product": "将买家与产品联系起来",
    Manufacturers: "制造商",
    ViewProducts: "查看产品",
    ManageBuyers: "管理买家",
    ViewPurchaseOrders: "查看采购订单",
  },
};

export default dictionary;
