import { useRef, useState, useEffect, RefObject } from "react";
import {
  homeRoutes,
  manufacturerRoutes,
  productRoutes,
  buyerRoutes,
  purchaseOrderRoutes,
} from "./routes";
import {
  NavigationContainerRef,
  useLinking,
  NavigationState,
} from "@react-navigation/native";

export function useRouting(): {
  isReady: boolean;
  navigationRef: RefObject<NavigationContainerRef | undefined>;
  initialState: NavigationState;
} {
  const navigationRef = useRef<NavigationContainerRef>();

  // @ts-ignore
  const { getInitialState } = useLinking(navigationRef, {
    prefixes: [],
    config: {
      [homeRoutes.home]: { path: "" },
      [homeRoutes.manufacturers]: { path: "manufacturers" },
      [homeRoutes.addInspectionReport]: { path: "inspection-report/new" },
      [homeRoutes.buyers]: { path: "buyers" },
      [homeRoutes.editManufacturer]: { path: "manufacturers/edit/:id" },
      [homeRoutes.addManufacturer]: { path: "manufacturers/new" },
      [homeRoutes.manufacturerName]: { path: "manufacturer/name" },
      [homeRoutes.manufacturerAddress]: { path: "manufacturer/address/:id" },
      [homeRoutes.logout]: { path: "logout" },
      [homeRoutes.createUser]: { path: "auth/user/create" },
      [homeRoutes.manufacturerDescription]: {
        path: "manufacturer/description/:id",
      },
      [homeRoutes.manufacturerBusinessLicenseUpload]: {
        path: "manufacturer/business-license/:id",
      },
      [homeRoutes.manufacturerCELicenseUpload]: {
        path: "manufacturer/ce-license/:id",
      },
      [homeRoutes.manufacturerFDALicenseUpload]: {
        path: "manufacturer/fda-license/:id",
      },
      [homeRoutes.manufacturerISOLicenseUpload]: {
        path: "manufacturer/iso-license/:id",
      },
      [homeRoutes.manufacturerIspectionDocument]: {
        path: "manufacturer/ispection-document/:id",
      },
      [homeRoutes.manufacturerNioshLicense]: {
        path: "manufacturer/niosh-document/:id",
      },
      [manufacturerRoutes.manufacturerProducts]: { path: "manufacturer/:id" },
      [manufacturerRoutes.addProduct]: { path: "product/new/:manufacturerId" },
      [manufacturerRoutes.editProduct]: {
        path: "product/:manufacturerId/:id/edit",
      },
      [productRoutes.productName]: { path: "product/name/:manufacturerId" },
      [productRoutes.productDescription]: {
        path: "product/description/:manufacturerId/:id",
      },
      [productRoutes.productCeCertificationUpload]: {
        path: "product/ce-upload/:manufacturerId/:id",
      },
      [productRoutes.productFdaCertificationUpload]: {
        path: "product/fda-upload/:manufacturerId/:id",
      },
      [buyerRoutes.buyerProducts]: { path: "buyers/:id/products" },
      [buyerRoutes.associateBuyerProduct]: { path: "buyers/:id/associate" },
      [productRoutes.productDetails]: { path: "product/:id/details" },
      [manufacturerRoutes.manufacturerDetails]: {
        path: "manufacturer/:id/details",
      },
      [purchaseOrderRoutes.associatePurchaseOrderProduct]: {
        path: "purchase-order/:id/associate",
      },
      [purchaseOrderRoutes.purchaseOrderProducts]: {
        path: "purchase-order/:id/products-list",
      },
      [buyerRoutes.buyerPurchaseOrders]: {
        path: "buyers/:id/purchase-orders",
      },
      [buyerRoutes.buyersListForOrders]: {
        path: "buyers-purchases",
      },
      [purchaseOrderRoutes.globalPurchaseOrderList]: {
        path: "purchase-orders",
      },
      [purchaseOrderRoutes.purchaseOrderShippingDate]: {
        path: "purchase-orders/:buyerId/:id/shipment",
      },
      [purchaseOrderRoutes.purchaseOrderManufacturerNumber]: {
        path: "purchase-orders/:buyerId/:id/manufacturer-number",
      },
      [purchaseOrderRoutes.purchaseOrderNumber]: {
        path: "purchase-orders/:buyerId/manufacturer-number",
      },
      [buyerRoutes.editPurchaseOrder]: {
        path: "purchase-orders/:buyerId/:id/edit",
      },
      [purchaseOrderRoutes.addPurchaseOrder]: {
        path: "purchase-orders/:buyerId/add",
      },
      [buyerRoutes.buyerPurchaseOrdersWithoutAdd]: {
        path: "buyer/purchase-orders/:id",
      },
      [purchaseOrderRoutes.purchaseOrderDetails]: {
        path: "purchase-orders/:id/details",
      },
    },
  });

  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState<any>();

  useEffect(() => {
    getInitialState()
      .catch(() => {})
      .then((state) => {
        setInitialState(state);
        setIsReady(true);
      });
  }, [getInitialState]);

  return { isReady, navigationRef, initialState };
}
