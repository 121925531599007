import { Product, PurchaseOrder, InspectionReport } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { isEmpty, isNull, get } from "lodash";
import { purchaseOrderRoutes } from "lib/routing/routes";

export const mapPurchaseOrdersToUI = (p: Product[]): ActionableListItem[] =>
  p.map((p) => ({
    name: p.name,
    id: p.id,
    disabled: false,
    hasChevron: true,
    hasIndicator: true,
    isPublished: !!p.isPublished,
    // TODO: find out if `${productRoutes.productDetails}/${p.id}` works
    route: purchaseOrderRoutes.purchaseOrderDetails,
  }));

export const validateInspectionReport = (p: InspectionReport | undefined) =>
  !p || hasInspectionReportDoc(p);

const hasInspectionReportDoc = (p: InspectionReport) =>
  p && !isEmpty(p.docLink);

export const mapPurchaseOrderToUI = (
  p: PurchaseOrder
): ActionableListItem[] => [
  {
    id: "Purchase Order Number",
    name: "Purchase Order Number",
    route: purchaseOrderRoutes.purchaseOrderNumber,
    isPublished: !isEmpty(p.poId),
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Manufacturer Purchase Order Number",
    name: "Manufacturer Purchase Order Number",
    route: purchaseOrderRoutes.purchaseOrderManufacturerNumber,
    isPublished: !isEmpty(p.manufacturerPoId) ? true : undefined,
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.poId),
  },
  {
    id: "Shipment",
    name: "Shipment",
    route: purchaseOrderRoutes.purchaseOrderShippingDate,
    isPublished: !isEmpty(p.shippingDate) ? true : undefined,
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.poId),
  },
  {
    id: "Products",
    name: "Products",
    route: purchaseOrderRoutes.purchaseOrderProducts,
    isPublished: undefined,
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(p.poId),
  },
];

export const initialValues: ActionableListItem[] = [
  {
    id: "PO Number",
    name: "PO Number",
    route: purchaseOrderRoutes.purchaseOrderNumber,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Manufacturer PO Number",
    name: "Manufacturer PO Number",
    route: purchaseOrderRoutes.purchaseOrderManufacturerNumber,
    isPublished: undefined,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Shipment",
    name: "Shipment",
    route: purchaseOrderRoutes.purchaseOrderShippingDate,
    isPublished: undefined,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Products",
    name: "Products",
    route: purchaseOrderRoutes.purchaseOrderProducts,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
];
