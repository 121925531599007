import React, { Fragment, useRef, Profiler } from "react";
import { StyleSheet, View } from "react-native";
import * as yup from "yup";
import { Formik } from "formik";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { TextInput, Scaffold } from "components";
import { useNavigation } from "@react-navigation/native";
import { buyerRoutes } from "lib/routing/routes";
import { createOrUpdatePurchaseOrder } from "./utils";
import fixNull from "lib/fix-null";

export default function PurchaseOrderManufacturerNumber() {
  const id = useNavigationParam("id");
  const buyerId = useNavigationParam("buyerId");
  const manufacturerPoId = useNavigationParam("manufacturerPoId") || "";
  const customBack = useCustomBack();
  const isSubmitting = useRef(false);
  const navigation = useNavigation();

  return (
    <Scaffold>
      <Formik
        validationSchema={validatorSchema}
        initialValues={{
          manufacturerPoId: fixNull(manufacturerPoId),
        }}
        onSubmit={async (values) => {
          if (isSubmitting.current === false) {
            isSubmitting.current = true;
            const purchaseOrder = await createOrUpdatePurchaseOrder(buyerId, {
              id,
              ...values,
            });
            isSubmitting.current = false;

            return navigation.navigate(buyerRoutes.editPurchaseOrder, {
              ...purchaseOrder,
              buyerId: purchaseOrder.buyer?.id,
            });
          }
        }}
      >
        {({ submitForm }) => {
          customBack(submitForm);
          return (
            <Fragment>
              <TextInput
                name="manufacturerPoId"
                placeholder="Enter the Manufacturer Number for this purchase order"
              />
              <SubmitErrorHandler />
            </Fragment>
          );
        }}
      </Formik>
    </Scaffold>
  );
}

const validatorSchema = yup.object().shape({
  manufacturerPoId: yup.string().nullable(),
});
