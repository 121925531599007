import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import { TextInput, Scaffold } from "components";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation } from "@react-navigation/native";
import { homeRoutes } from "lib/routing/routes";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer } from "models";
import fixNull from "lib/fix-null";

export default function ManufacturerName() {
  const navigation = useNavigation();
  const id = useNavigationParam<string>("id");
  const nameParam = useNavigationParam<string>("name", "") || "";
  const customBack = useCustomBack();
  const isSubmitting = useRef(false);

  return (
    <Scaffold>
      <Formik
        initialValues={{ name: fixNull(nameParam) }}
        validationSchema={validatorSchema}
        onSubmit={async (values) => {
          if (isSubmitting.current === false) {
            isSubmitting.current = true;

            if (id) {
              const manufacturer = await DataStore.query(Manufacturer, id);
              await DataStore.save(
                Manufacturer.copyOf(manufacturer, (updated) => {
                  updated.name = values.name;
                })
              );

              isSubmitting.current = false;
              return navigation.navigate(homeRoutes.editManufacturer, {
                id: manufacturer.id,
              });
            }

            const manufacturer = await DataStore.save(
              new Manufacturer({
                name: values.name,
                isPublished: false,
                numberOfProducts: 0,
              })
            );

            isSubmitting.current = false;
            return navigation.navigate(homeRoutes.editManufacturer, {
              id: manufacturer.id,
            });
          }
        }}
      >
        {({ submitForm }) => {
          customBack(submitForm);
          return (
            <>
              <TextInput
                name="name"
                placeholder="Enter a name for the manufacturer"
              />
              <SubmitErrorHandler />
            </>
          );
        }}
      </Formik>
    </Scaffold>
  );
}

const validatorSchema = yup.object().shape({
  name: yup.string().required("Field is required"),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    paddingHorizontal: 40,
    backgroundColor: "white",
  },
});
