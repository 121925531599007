import {
  PersistentModelConstructor,
  ProducerModelPredicate,
  DataStore,
} from "@aws-amplify/datastore";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";

export default function useSubscription<
  T extends Readonly<
    {
      id: string;
    } & Record<string, any>
  >
>(
  model: PersistentModelConstructor<T>,
  criteria?: ProducerModelPredicate<T> | undefined
): void {
  useFocusEffect(
    useCallback(() => {
      const subscription = DataStore.observe(model).subscribe((e) => {});

      return () => {
        subscription.unsubscribe();
      };
    }, [criteria])
  );
}
