import React from "react";
import { NavigationProp } from "@react-navigation/native";
import { StyleSheet, TouchableOpacity } from "react-native";
import { homeRoutes } from "lib/routing/routes";
import { Text } from "react-native-elements";
import { colors } from "theme";
import AddButton from "components/buttons/AddButton";

interface Props {
  navigation: NavigationProp<any>;
}

export default function HeaderRightButton({ navigation }: Props): JSX.Element {
  const goToAddManufacturer = () =>
    navigation.navigate(homeRoutes.addManufacturer);
  return (
    <TouchableOpacity style={styles.button} onPress={goToAddManufacturer}>
      <AddButton />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 44,
    alignItems: "center",
    justifyContent: "center",
  },
});
