import React, { FunctionComponent, useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Manufacturer, Product } from "models";
import { DataStore } from "@aws-amplify/datastore";

import AddProductForm from "screens/AddProduct/AddProductForm";
import useNavigationParam from "lib/use-navigation-param";
import alert from "lib/alert";
import { useNavigation, useRoute } from "@react-navigation/native";
import { get } from "lodash";
import useHandleBack from "lib/use-handle-back";
import { manufacturerRoutes, productRoutes } from "lib/routing/routes";
import { useLoadDocument } from "lib/use-load-documents";

const AddProduct: FunctionComponent = () => {
  const manufacturerId = useNavigationParam("manufacturerId");
  const navigation = useNavigation();
  const productParam = get(useRoute(), "params", {}) as Product;
  const [loading, product] = useLoadDocument(Product, get(productParam, "id"));

  useHandleBack(async () => {
    navigation.navigate(manufacturerRoutes.manufacturerProducts, {
      id: manufacturerId,
      name:
        product?.manufacturer?.name ||
        (await DataStore.query(Manufacturer, manufacturerId)).name,
    });
  }, [navigation, product]);

  const publishProduct = async (product: Product) => {
    try {
      const currentManufacturer = await DataStore.query(
        Manufacturer,
        manufacturerId
      );

      await DataStore.save(
        Product.copyOf(product, (updated) => {
          updated.manufacturer = currentManufacturer;
          updated.isPublished = true;
        })
      );

      navigation.navigate(manufacturerRoutes.manufacturerProducts, {
        id: currentManufacturer.id,
        name: currentManufacturer.name,
      });
    } catch (e) {
      alert({ message: "Error saving product.", title: "Error" });
      console.error(e.message, { manufacturerId });
    }
  };

  return (
    <AddProductForm
      manufacturerId={manufacturerId}
      product={product}
      onSubmit={publishProduct}
      loading={loading}
    />
  );
};
export default AddProduct;
