import React, {
  FunctionComponent,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useFocusEffect } from "@react-navigation/native";

import { DataStore } from "@aws-amplify/datastore";
import { Manufacturer } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { homeRoutes, manufacturerRoutes } from "lib/routing/routes";
import { useNavigation } from "@react-navigation/native";
import ActionableList from "components/actionable-list/ActionableList";
import { mapManufacturersToUI } from "./ManufacturersList.utils";
import { sortBy } from "lodash";
const ManufacturersList: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [manufacturersItems, setManufacturersItems] = useState<
    ActionableListItem[]
  >([]);
  const navigation = useNavigation();

  const onItemPress = (item: ActionableListItem) => {
    if (item.isPublished)
      return navigation.navigate(manufacturerRoutes.manufacturerProducts, {
        id: item.id,
        name: item.name,
      });

    return navigation.navigate(homeRoutes.editManufacturer, {
      id: item.id,
    });
  };

  const fetchManufacturers = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log("fetching");
      const manufacturers = await DataStore.query(Manufacturer);
      const sortedByName = sortBy(manufacturers, (m) => m.name);
      setIsLoading(false);
      setManufacturers(sortedByName);
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetchManufacturers();
      const subscription = DataStore.observe(Manufacturer).subscribe((msg) => {
        console.log("subscribe", msg);
        fetchManufacturers();
      });

      return () => {
        console.log("unsubscribe");
        return subscription.unsubscribe();
      };
    }, [])
  );

  useMemo(() => {
    console.log("running", manufacturers);
    setIsLoading(true);
    async function loadItems() {
      const result = await mapManufacturersToUI(manufacturers);
      setIsLoading(false);

      setManufacturersItems(result);
    }

    loadItems();
  }, [manufacturers]);

  return (
    <ActionableList
      items={manufacturersItems}
      loading={isLoading}
      onItemPress={onItemPress}
    />
  );
};

export default ManufacturersList;
