import { Manufacturer } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { isEmpty } from "lodash";
import { homeRoutes } from "lib/routing/routes";
import {
  validateCeDoc,
  validateFdaDoc,
  validateIsoDoc,
  validateNioshDoc,
} from "screens/Manufacturers/ManufacturersList.utils";

export const mapManufacturerToUI = (m: Manufacturer): ActionableListItem[] => [
  {
    id: "Name",
    name: "Name",
    route: homeRoutes.manufacturerName,
    isPublished: !isEmpty(m.name),
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Address",
    name: "Address",
    route: homeRoutes.manufacturerAddress,
    isPublished: !isEmpty(m.address),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },
  {
    id: "Description",
    name: "Description",
    route: homeRoutes.manufacturerDescription,
    isPublished: !isEmpty(m.description),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },
  {
    id: "Business License Document",
    name: "Business License Document",
    route: homeRoutes.manufacturerBusinessLicenseUpload,
    isPublished:
      !isEmpty(m.businessLicenseNumber) && !isEmpty(m.businessLicenseDocLink),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },

  {
    id: "Inspection Document",
    name: "Inspection Document",
    route: homeRoutes.manufacturerIspectionDocument,
    isPublished: !isEmpty(m.qaInspectionDocLink),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },

  {
    id: "ISO Document",
    name: "ISO Document",
    route: homeRoutes.manufacturerISOLicenseUpload,
    isPublished: validateIsoDoc(m),
    // !isEmpty(m.isoAddressOnFile),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },
  {
    id: "FDA License",
    name: "FDA License",
    route: homeRoutes.manufacturerFDALicenseUpload,
    isPublished: validateFdaDoc(m),
    // !isEmpty(m.fdaAddressOnFile),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },
  {
    id: "CE License",
    name: "CE License",
    route: homeRoutes.manufacturerCELicenseUpload,
    isPublished: validateCeDoc(m),
    hasChevron: true,
    hasIndicator: true,
    disabled: isEmpty(m.name),
  },
];

export const initialValues: ActionableListItem[] = [
  {
    id: "Name",
    name: "Name",
    route: homeRoutes.manufacturerName,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: false,
  },
  {
    id: "Address",
    name: "Address",
    route: homeRoutes.manufacturerAddress,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Description",
    name: "Description",
    route: homeRoutes.manufacturerDescription,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Business License Document",
    name: "Business License Document",
    route: homeRoutes.manufacturerBusinessLicenseUpload,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "Inspection Document",
    name: "Inspection Document",
    route: homeRoutes.manufacturerIspectionDocument,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },

  {
    id: "ISO Document",
    name: "ISO Document",
    route: homeRoutes.manufacturerISOLicenseUpload,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "FDA License",
    name: "FDA License",
    route: homeRoutes.manufacturerFDALicenseUpload,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
  {
    id: "CE License",
    name: "CE License",
    route: homeRoutes.manufacturerCELicenseUpload,
    isPublished: false,
    hasChevron: true,
    hasIndicator: true,
    disabled: true,
  },
];
