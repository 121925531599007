import React, { FunctionComponent } from "react";
import ActionableHeader from "components/ActionableHeader/ActionableHeader";

interface Props {
  onPress: () => void;
}

const ManufacturerProductsActionBar: FunctionComponent<Props> = ({
  onPress,
}) => <ActionableHeader onPress={onPress} title="Products" />;

export default ManufacturerProductsActionBar;
