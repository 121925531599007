import React, { Fragment, useRef, Profiler, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as yup from "yup";
import { Formik } from "formik";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import {
  TextInput,
  FileUploadInput,
  SecondaryButton,
  Scaffold,
} from "components";
import { useNavigation } from "@react-navigation/native";
import { getValuesWithUploadMechanism, updateProduct } from "./utils";
import useUploadProgress from "lib/use-upload-progress";
import { Product } from "models";
import { DataStore } from "@aws-amplify/datastore";
import fixNull from "lib/fix-null";

export default function ProducFDACertification() {
  const manufacturerId = useNavigationParam("manufacturerId");
  const id = useNavigationParam("id");
  const fdaNumber = useNavigationParam("fdaNumber") || "";
  const fdaDocLink = useNavigationParam("fdaDocLink");
  const customBack = useCustomBack();
  const isSubmitting = useRef(false);
  const navigation = useNavigation();
  const [Upload, setProgress] = useUploadProgress();

  return (
    <Scaffold bottom>
      <Formik
        validationSchema={validatorSchema}
        initialValues={{
          fdaNumber: fixNull(fdaNumber),
          fdaDocLink: fixNull(fdaDocLink),
          hasNoFda: null,
        }}
        onSubmit={async (values) => {
          if (isSubmitting.current === false) {
            isSubmitting.current = true;
            const product = await DataStore.query(Product, id);
            const result = await getValuesWithUploadMechanism(
              manufacturerId,
              product,
              values.hasNoFda
                ? { fdaDocLink: null, fdaNumber: null, hasNoFda: true }
                : values,
              ["fdaDocLink"],
              setProgress
            );

            isSubmitting.current = false;
            updateProduct(product, result, manufacturerId, navigation);
          }
        }}
      >
        {({ submitForm, setFieldValue }) => {
          customBack(submitForm);
          return (
            <Fragment>
              <TextInput
                name="fdaNumber"
                multiline={false}
                placeholder="Enter a FDA license number"
              />
              <FileUploadInput name="fdaDocLink" />
              <SecondaryButton
                title="Not Licensed"
                containerStyle={styles.pickerButton}
                onPress={() => {
                  setFieldValue("hasNoFda", true);
                  submitForm();
                }}
              />
              <SubmitErrorHandler />
              {Upload}
            </Fragment>
          );
        }}
      </Formik>
    </Scaffold>
  );
}

const validatorSchema = yup.object().shape({
  fdaNumber: yup.string().nullable(),
  fdaDocLink: yup.string().nullable(),
  hasNoFda: yup.string().nullable(),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    backgroundColor: "white",
  },
  pickerButton: {
    paddingVertical: 30,
  },
});
