import { omit, last, get } from "lodash";
import { getCurrentImageFieldName } from "components";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import { Manufacturer } from "models";
import { DataStore } from "@aws-amplify/datastore";
import { NavigationProp } from "@react-navigation/native";
import { homeRoutes } from "lib/routing/routes";
import { PutItem } from "lib/Storage";

export interface IProgress {
  loaded: number;
  total: number;
}

export async function getValuesWithUploadMechanism(
  values: { [x: string]: any },
  uploadKeys: string[] = [],
  progressCallback?: Function
): Promise<Record<string, any>> {
  let omitPaths: string[] = [];

  for (let index = 0; index < uploadKeys.length; index++) {
    const uploadKey = uploadKeys[index];
    const preKey = getCurrentImageFieldName(uploadKey);
    const preValue = values[preKey];

    if (preValue) {
      const name = `inspection-report/${
        values.purchaseOrderNumber
      }/${uploadKey}.${last(preValue.name.split("."))}`;

      const response = await fetch(preValue.uri);
      const blob = await response.blob();

      if (progressCallback) progressCallback({ loaded: 0.0001, total: 1 });

      const insertedImage = await PutItem(name, blob, {
        contentType: mimeType.lookup(name),
        level: "protected",
        // @ts-ignore
        progressCallback(progress) {
          if (progressCallback) {
            progressCallback(progress);
          }
        },
      });

      values[uploadKey] = get(insertedImage, "key");
      omitPaths.push(preKey);
    }
  }

  return omit(values, omitPaths);
}
