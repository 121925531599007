import React, { FunctionComponent, useCallback } from "react";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import ActionableList from "components/actionable-list/ActionableList";
import { PurchaseOrder } from "models";
import { useNavigation } from "@react-navigation/native";
import {
  mapPurchaseOrderToUI,
  initialValues,
} from "screens/PurchaseOrder/AddPurchaseOrder.utils";

interface Props {
  purchaseOrder?: PurchaseOrder;
  loading: boolean;
  onSubmit(purchaseOrder: PurchaseOrder): Promise<void>;
  buyerId: string;
}

const AddPurchaseOrderForm: FunctionComponent<Props> = ({
  purchaseOrder,
  buyerId,
  loading,
}) => {
  const navigation = useNavigation();
  const fields = purchaseOrder
    ? mapPurchaseOrderToUI(purchaseOrder)
    : initialValues;

  const onItemPress = useCallback(
    (item: ActionableListItem) => {
      navigation.navigate(item.route as string, { ...purchaseOrder, buyerId });
    },
    [navigation, JSON.stringify(purchaseOrder), buyerId]
  );

  return (
    <ActionableList
      items={fields}
      loading={loading}
      onItemPress={onItemPress}
    />
  );
};

export default AddPurchaseOrderForm;
