import { Product, Manufacturer } from "models";
import { DataStore } from "@aws-amplify/datastore";
import { omit, last, get } from "lodash";
import { NavigationProp } from "@react-navigation/native";
import { getCurrentImageFieldName } from "components";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import { manufacturerRoutes } from "lib/routing/routes";
import { PutItem } from "lib/Storage";

export async function getValuesWithUploadMechanism(
  manufacturerId: string,
  product: Product,
  values: { [x: string]: any },
  uploadKeys: string[] = [],
  progressCallback?: Function
): Promise<Record<string, any>> {
  let omitPaths: string[] = [];

  for (let index = 0; index < uploadKeys.length; index++) {
    const uploadKey = uploadKeys[index];
    const preKey = getCurrentImageFieldName(uploadKey);
    const preValue = values[preKey];

    if (preValue) {
      const name = `manufacturer/${manufacturerId}/products/${
        product.id
      }/${uploadKey}.${last(preValue.name.split("."))}`;

      const response = await fetch(preValue.uri);
      const blob = await response.blob();

      if (progressCallback) progressCallback({ loaded: 0.0001, total: 1 });

      const insertedImage = await PutItem(name, blob, {
        contentType: mimeType.lookup(name),
        level: "protected",
        // @ts-ignore
        progressCallback(progress) {
          if (progressCallback) {
            progressCallback(progress);
          }
        },
      });

      values[uploadKey] = get(insertedImage, "key");
      omitPaths.push(preKey);
    }
  }

  return omit(values, omitPaths);
}

export async function updateProduct(
  product: Product,
  values: Record<string, any>,
  manufacturerId: string,
  navigation?: NavigationProp<any>
): Promise<void> {
  await DataStore.save(
    Product.copyOf(product, (updated) => {
      Object.keys(values).map((key) => {
        // @ts-ignore
        updated[key] = values[key];
      });
    })
  );

  if (navigation) {
    navigation.navigate(manufacturerRoutes.editProduct, {
      ...product,
      manufacturerId,
    });
  }
}

export async function createOrUpdateProduct(
  manufacturerId: string,
  values: Product | Partial<Product>
): Promise<Product> {
  if (!manufacturerId) {
    throw new Error("No Manufacturer");
  }

  const manufacturer = await DataStore.query(Manufacturer, manufacturerId);

  if (values.id) {
    const product = await DataStore.query(Product, values.id);

    return DataStore.save(
      Product.copyOf(product, (updated) => {
        Object.keys(values).map((key) => {
          // @ts-ignore
          updated[key] = values[key];
        });

        updated.manufacturer = manufacturer;
      })
    );
  }

  // Initialize the number of products
  let numberOfProducts = manufacturer.numberOfProducts;
  if (numberOfProducts == null || numberOfProducts == undefined) {
    numberOfProducts = (await DataStore.query(Product)).filter(
      (p) => p.manufacturer?.id === manufacturer.id
    ).length;
  }

  const product = await DataStore.save(
    // @ts-ignore
    new Product({ ...omit(values, ["id"]), isPublished: false, manufacturer })
  );

  await DataStore.save(
    Manufacturer.copyOf(manufacturer, (updated) => {
      updated.numberOfProducts = numberOfProducts! + 1;
    })
  );
  return new Promise((resolve) => {
    resolve(product);
  });
}
