import React, { useState, useEffect, useCallback, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  homeRoutes,
  productRoutes,
  buyerRoutes,
  purchaseOrderRoutes,
} from "lib/routing/routes";
import { Button, Text } from "react-native-elements";
import Space from "components/Space/Space";
import { Auth, I18n } from "aws-amplify";
import { allow } from "components/auth/utils";
import { useI18n } from "hooks/use-i18n";
import { colors, fontFamily } from "theme";
import { DataStore } from "@aws-amplify/datastore";
import { first } from "lodash";
import { Buyer, ProductOwnership } from "models";
import useSubscription from "lib/use-subscription";

export default function HomeScreen() {
  const i18nKeys = useI18n();
  const navigation = useNavigation();
  const buyer = useRef<Buyer>();
  const [currentUserGroups, setCurrentUserGroups] = useState<string[]>([]);
  useSubscription(ProductOwnership);

  useEffect(() => {
    async function hasAuth() {
      const session = await Auth.currentSession();
      setCurrentUserGroups(session.getAccessToken().payload["cognito:groups"]);
    }
    hasAuth();
  }, []);

  useEffect(() => {
    async function init() {
      const newBuyer = first(await DataStore.query(Buyer));
      if (buyer.current?.id != newBuyer?.id) buyer.current = newBuyer;
    }

    let subscription: any = null;

    if (!buyer.current) {
      init();
      subscription = DataStore.observe(Buyer).subscribe(() => init());
    }

    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [buyer.current?.id]);

  const goBuyerProfile = useCallback(() => {
    function load() {
      if (buyer.current?.id) {
        navigation.navigate(buyerRoutes.buyerProducts, {
          id: buyer.current?.id,
        });
      } else {
        setTimeout(() => {
          load();
        }, 500);
      }
    }

    load();
  }, []);

  const goToAllPurchaseOrders = useCallback(() => {
    navigation.navigate(purchaseOrderRoutes.globalPurchaseOrderList);
  }, [navigation]);

  const goToPurchaseOrders = useCallback(() => {
    navigation.navigate(buyerRoutes.buyersListForOrders);
  }, [navigation]);

  const goToPurchaseOrdersWithBuyer = useCallback(() => {
    function load() {
      if (buyer.current?.id) {
        navigation.navigate(buyerRoutes.buyerPurchaseOrdersWithoutAdd, {
          id: buyer.current?.id,
        });
      } else {
        setTimeout(() => {
          load();
        }, 500);
      }
    }

    load();
  }, [navigation]);

  return (
    <View style={styles.container}>
      {allow(["brokerteam", "broker"], currentUserGroups) && (
        <Button
          title={I18n.get(i18nKeys["ManageBuyers"])}
          onPress={goToPurchaseOrders}
        />
      )}
      {allow(["brokerteam", "broker"], currentUserGroups) && (
        <>
          <Space size={5} />
          <Button
            title={I18n.get(i18nKeys["ViewPurchaseOrders"])}
            onPress={goToAllPurchaseOrders}
          />
        </>
      )}
      {allow(["brokerteam", "broker"], currentUserGroups) && (
        <>
          <Space size={5} />
          <Button
            title={I18n.get(i18nKeys["Add Manufacturer/Product"])}
            onPress={() => navigation.navigate(homeRoutes.manufacturers)}
          />
        </>
      )}

      {allow(["broker"], currentUserGroups) && (
        <>
          <Space size={5} />
          <Button
            title={I18n.get(i18nKeys["Create A New User"])}
            onPress={() => navigation.navigate(homeRoutes.createUser)}
          />
        </>
      )}

      {allow(["inspector", "broker", "brokerteam"], currentUserGroups) && (
        <>
          <Space size={5} />
          <Button
            title={I18n.get(i18nKeys["Add Inspection Report"])}
            onPress={() => navigation.navigate(homeRoutes.addInspectionReport)}
          />
        </>
      )}

      {allow(["buyer"], currentUserGroups) && (
        <>
          <Button
            title={I18n.get(i18nKeys["ViewPurchaseOrders"])}
            onPress={goToPurchaseOrdersWithBuyer}
          />
        </>
      )}

      <Space size={8} />
      <Text
        style={styles.link}
        onPress={() => navigation.navigate(homeRoutes.logout)}
      >
        {I18n.get(i18nKeys["Sign Out"])}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  link: {
    fontSize: 14,
    fontFamily: fontFamily.primary,
    fontWeight: "600",
    color: colors.grey0,
  },
  button: {
    paddingBottom: 30,
  },
});
