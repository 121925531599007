import { omit, last, get } from "lodash";
import { getCurrentImageFieldName } from "components";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import { Manufacturer } from "models";
import { DataStore } from "@aws-amplify/datastore";
import { NavigationProp } from "@react-navigation/native";
import { homeRoutes } from "lib/routing/routes";
import { PutItem } from "lib/Storage";

export interface IProgress {
  loaded: number;
  total: number;
}

export async function getValuesWithUploadMechanism(
  manufacturer: Manufacturer,
  values: { [x: string]: any },
  uploadKeys: string[] = [],
  progressCallback?: Function
): Promise<
  [Record<string, any>, (navigation: NavigationProp<any>) => Promise<void>]
> {
  let omitPaths: string[] = [];

  for (let index = 0; index < uploadKeys.length; index++) {
    const uploadKey = uploadKeys[index];
    const preKey = getCurrentImageFieldName(uploadKey);
    const preValue = values[preKey];

    if (preValue) {
      const name = `manufacturer/${manufacturer.id}/${uploadKey}.${last(
        preValue.name.split(".")
      )}`;

      const response = await fetch(preValue.uri);
      const blob = await response.blob();

      if (progressCallback) progressCallback({ loaded: 0.0001, total: 1 });

      const insertedImage = await PutItem(name, blob, {
        contentType: mimeType.lookup(name),
        level: "protected",
        // @ts-ignore
        progressCallback(progress) {
          if (progressCallback) {
            progressCallback(progress);
          }
        },
      });

      values[uploadKey] = get(insertedImage, "key");
      omitPaths.push(preKey);
    }
  }

  const result = omit(values, omitPaths);

  return [
    result,
    (navigation) => updateManufacturer(manufacturer, result, navigation),
  ];
}

export async function updateManufacturer(
  manufacturer: Manufacturer,
  values: Record<string, any>,
  navigation?: NavigationProp<any>
): Promise<void> {
  await DataStore.save(
    Manufacturer.copyOf(manufacturer, (updated) => {
      Object.keys(values).map((key) => {
        // @ts-ignore
        updated[key] = values[key];
      });
    })
  );

  if (navigation) {
    navigation.navigate(homeRoutes.editManufacturer, {
      id: manufacturer.id,
    });
  }
}
