import { Manufacturer, Product } from "models";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import { manufacturerRoutes } from "lib/routing/routes";
import { isEmpty, isNull, get } from "lodash";
import { DataStore } from "@aws-amplify/datastore";

interface MapManufacturersToUIOptions {
  hasChevron?: ActionableListItem["hasChevron"];
  hasIndicator?: ActionableListItem["hasIndicator"];
}

export const mapManufacturersToUI = async (
  manufacturers: Manufacturer[],
  options?: MapManufacturersToUIOptions
): Promise<ActionableListItem[]> => {
  return Promise.all(
    manufacturers.map(async (m) => ({
      id: m.id,
      name: m.name,
      badge: m.numberOfProducts,
      hasChevron: get(options, "hasChevron", false),
      hasIndicator: get(options, "hasIndicator", true),
      route: manufacturerRoutes.manufacturerProducts,
      isPublished: m.isPublished,
    }))
  );
};

// This is based on every required attribute for type Manufacturer on schema.graphql
export const determineManufacturerIsPublished = (m: Manufacturer): boolean =>
  !isEmpty(m.id) &&
  !isEmpty(m.name) &&
  !isEmpty(m.address) &&
  !isEmpty(m.description) &&
  !isEmpty(m.businessLicenseDocLink) &&
  !isEmpty(m.businessLicenseNumber) &&
  !isEmpty(m.qaInspectionDocLink) &&
  validateIsoDoc(m) &&
  (!!validateFdaDoc(m) || !!validateCeDoc(m));

export const validateIsoDoc = (m: Manufacturer) =>
  m.hasNoIso || (!isEmpty(m.isoDocLink) && !isEmpty(m.isoNumber));

export const validateNioshDoc = (m: Manufacturer) =>
  m.hasNoNiosh || (!isEmpty(m.nioshDocLink) && !isEmpty(m.nioshNumber));

export const validateFdaDoc = (m: Manufacturer) =>
  hasFdaDoc(m) || (hasCeDoc(m) ? undefined : false);

export const validateCeDoc = (m: Manufacturer) =>
  hasCeDoc(m) || (hasFdaDoc(m) ? undefined : false);

const hasFdaDoc = (m: Manufacturer) =>
  !isEmpty(m.fdaDocLink) && !isEmpty(m.fdaNumber);

const hasCeDoc = (m: Manufacturer) =>
  !isEmpty(m.ceDocLink) && !isEmpty(m.ceNumber);
