import React, { useCallback, useState, useEffect, Fragment } from "react";
import { View, StyleSheet } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { colors } from "theme";
import { noop } from "lodash";

export interface IProgress {
  loaded: number;
  total: number;
}
export const InitialProgress: IProgress = { loaded: 0, total: 1 };

interface IProps {
  progress: IProgress;
}
export default function UpdateProgress({ progress }) {
  if (
    progress.loaded != InitialProgress.loaded &&
    progress.loaded != progress.total
  ) {
    const fill = (progress.loaded / progress.total) * 100;
    return (
      <View style={styles.progressContainer}>
        <View style={styles.modalOpacity} />
        <AnimatedCircularProgress
          size={120}
          width={15}
          fill={fill < 20 ? 20 : fill}
          tintColor={colors.green}
          backgroundColor={colors.red}
        />
      </View>
    );
  }
  return <View />;
}

const styles = StyleSheet.create({
  modalOpacity: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: colors.black,
    opacity: 0.3,
    flex: 1,
  },
  progressContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
});
