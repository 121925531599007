import React, { useState } from "react";
import { Dimensions, Platform } from "react-native";
import {
  SectionList,
  ImageBackground,
  Linking,
  StyleSheet,
  View,
} from "react-native";
import { Text } from "react-native-elements";
import { useLoadDocument } from "lib/use-load-documents";
import useFocusEffect from "lib/use-focus-effect";
import { Product } from "models";
import { useSections, data, getPreview } from "./ProductDetails.utils";
import useNavigationParam from "lib/use-navigation-param";
import { colors, units } from "theme";
import { Loading } from "components/actionable-list/ActionableList.style";

export default function ProductDetails() {
  const id = useNavigationParam("id");
  const [isLoading, product] = useLoadDocument(Product, id);
  const [ceURI, setCeURI] = useState<string>("");
  const [fdaURI, setFdaURI] = useState<string>("");
  const [isoURI, setIsoURI] = useState<string>("");
  const [docURI, setDocURI] = useState<string>("");

  const sections = useSections(ceURI, fdaURI, isoURI, docURI, product);

  useFocusEffect(() => {
    async function preview() {
      getPreview(setCeURI, product?.ceDocLink);
      getPreview(setFdaURI, product?.fdaDocLink);
      getPreview(setIsoURI, product?.isoDocLink);
      getPreview(setDocURI, product?.docLink);
    }
    preview();
  }, [product, fdaURI]);

  interface IItemProps {
    item: data;
  }

  const Item = ({ item }: IItemProps): JSX.Element => {
    if (!item.label) {
      return (
        <View style={styles.item}>
          <Text style={styles.itemText}>{item.value}</Text>
        </View>
      );
    }

    if (item.label && !item.isLink) {
      return (
        <View style={styles.item}>
          <Text style={styles.itemText}>{item.label}:</Text>
          <Text style={[styles.itemText, { paddingLeft: 5, flex: 1 }]}>
            {item.value}
          </Text>
        </View>
      );
    }

    if (item.isLink && item.isImage) {
      return (
        <View style={styles.imageAndTextContainer}>
          <View style={styles.imageContainer}>
            <ImageBackground
              style={styles.backgroundImage}
              source={{ uri: item.value }}
              resizeMode="contain"
            />
          </View>
          <Text
            style={styles.itemTextButtonUnderline}
            onPress={() => {
              Platform.OS === "web"
                ? window.open(item.value!, "_blank")
                : Linking.openURL(item.value!);
            }}
          >
            Click to open
          </Text>
        </View>
      );
    }

    if (item?.isLink) {
      return (
        <View style={styles.textContainer}>
          <Text
            style={styles.itemTextButtonUnderline}
            onPress={() => {
              Platform.OS === "web"
                ? window.open(item.value!, "_blank")
                : Linking.openURL(item.value!);
            }}
          >
            {item.label}
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.textContainer}>
        <Text
          style={styles.itemTextButtonUnderline}
          onPress={() => {
            Platform.OS === "web"
              ? window.open(item.value!, "_blank")
              : Linking.openURL(item.value!);
          }}
        >
          {item.label}
        </Text>
      </View>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SectionList
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
      stickySectionHeadersEnabled
      sections={sections}
      renderItem={({ item }) => <Item item={item} />}
      renderSectionHeader={({ section }) => (
        <View style={styles.header}>
          <Text h4>{section.title}</Text>
        </View>
      )}
      keyExtractor={(item) => item.id.toString()}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 50,
    paddingHorizontal: 20,
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
  },
  item: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  itemText: {
    color: colors.black,
    fontSize: 20,
  },
  itemTextButtonUnderline: {
    fontFamily: "Quicksand-Bold",
    color: colors.black,
    fontWeight: "900",
    fontSize: 18,
    textAlign: "center",
    paddingVertical: units.nudge * 2,
    textDecorationLine: "underline",
  },
  itemTextButton: {
    fontFamily: "Quicksand-Bold",
    color: colors.black,
    fontWeight: "900",
    fontSize: 18,
    textAlign: "center",
    paddingVertical: units.nudge * 2,
  },
  imageContainer: {
    flexDirection: "row",
  },
  imageAndTextContainer: { paddingTop: 20, paddingBottom: 20 },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
    height: Dimensions.get("window").height * 0.5,
  },
  header: {
    backgroundColor: colors.white,
    paddingVertical: units.nudge / 2,
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
