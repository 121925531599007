import React, { Fragment, FunctionComponent } from "react";
import { Icon, ListItem, Badge } from "react-native-elements";
import { ActionableListItem } from "../ActionableList.types";
import { colors } from "theme";
import { TouchableOpacity } from "react-native";

interface Props {
  item: ActionableListItem;
  onItemPress: (item: ActionableListItem) => void;
}

const Indicator: FunctionComponent<{ item: ActionableListItem }> = ({ item }) =>
  item.hasIndicator ? (
    <>
      {item.badge && item.isPublished ? (
        <Badge
          value={item.badge}
          status="primary"
          badgeStyle={{ backgroundColor: colors.grey0 }}
        />
      ) : null}
      <Icon
        name="stop"
        iconStyle={{ fontSize: 28 }}
        color={
          item.isPublished
            ? colors.green
            : item.isPublished === false
            ? colors.red
            : colors.grey0
        }
      />
    </>
  ) : (
    <Fragment />
  );

export default ({ item, onItemPress }: Props) => (
  <ListItem
    Component={TouchableOpacity}
    title={item.name}
    subtitle={item.subtitle}
    bottomDivider
    chevron={item.hasChevron}
    rightIcon={<Indicator item={item} />}
    onPress={(e) => {
      onItemPress(item);
    }}
    disabled={item.disabled}
  />
);
