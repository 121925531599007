import React, { useEffect, useState, useCallback } from "react";
import useLoadDocuments, { useLoadDocument } from "lib/use-load-documents";
import { PurchaseOrder } from "models";
import useNavigationParam from "lib/use-navigation-param";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import moment from "moment";
import { SectionList, View, StyleSheet } from "react-native";
import ListItem from "components/actionable-list/list-item/ListItem";
import { Text } from "react-native-elements";
import { colors, units } from "theme";
import { manufacturerRoutes, productRoutes } from "lib/routing/routes";
import { sortBy } from "lodash";
import { ProductOwnership } from "models";
import { Scaffold, ActionableHeader } from "components";
import { useNavigation } from "@react-navigation/native";
import Space from "components/Space/Space";
import ActionableList from "components/actionable-list/ActionableList";

export default function PurchaseOrderDetails() {
  const id = useNavigationParam("id");
  const navigation = useNavigation();

  const [purchaseOrdersUI, setPurchaseOrdersUI] = useState<
    ActionableListItem[]
  >([]);
  const [isLoadingPurchaseOrder, purchaseOrder] = useLoadDocument(
    PurchaseOrder,
    id
  );
  const [isLoadingProductOwnership, productOwnerships] = useLoadDocuments(
    ProductOwnership
  );

  const loading = isLoadingPurchaseOrder || isLoadingProductOwnership;

  useEffect(() => {
    if (purchaseOrder?.poId) {
      navigation.setOptions({
        title: `Purchase Order - ${purchaseOrder.poId}`,
      });
    }
  }, [purchaseOrder]);

  useEffect(() => {
    const newProducts = productOwnerships.filter(
      (po) => po.purchaseOrder?.id === id
    );

    setPurchaseOrdersUI(
      sortBy(newProducts, (o) => o?.product?.name).map((o) => ({
        id: o.product!.id,
        name: o.product!.name,
        hasChevron: false,
        hasIndicator: false,
        isPublished: true,
        route: manufacturerRoutes.manufacturerProducts,
      }))
    );
  }, [purchaseOrder, productOwnerships]);

  const onItemPress = useCallback(
    (item: ActionableListItem) => {
      navigation.navigate(productRoutes.productDetails, { id: item.id });
    },
    [navigation]
  );

  return (
    <>
      <Space size={1} />
      <ActionableHeader title="Products" />
      <Space size={2} />
      <ActionableList
        items={purchaseOrdersUI}
        loading={loading}
        onItemPress={onItemPress}
      />
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.white,
    paddingTop: units.nudge * 2,
    // paddingBottom: units.nudge / 2,
    paddingHorizontal: units.nudge * 2,
  },
});
