// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Manufacturer, Product, ProductOwnership, PurchaseOrder, Buyer, InspectionReport } = initSchema(schema);

export {
  Manufacturer,
  Product,
  ProductOwnership,
  PurchaseOrder,
  Buyer,
  InspectionReport
};