import React, { FunctionComponent, useCallback } from "react";
import { ActionableListItem } from "components/actionable-list/ActionableList.types";
import FloatingButton from "components/buttons/FloatingButton";
import ActionableList from "components/actionable-list/ActionableList";
import { Product } from "models";
import { useNavigation } from "@react-navigation/native";
import {
  mapProductToUI,
  initialValues,
  determineProductIsPublished,
} from "screens/AddProduct/AddProduct.utils";

interface Props {
  product?: Product;
  loading: boolean;
  onSubmit(product: Product): Promise<void>;
  manufacturerId: string;
}

const AddProductForm: FunctionComponent<Props> = ({
  product,
  onSubmit,
  manufacturerId,
  loading,
}) => {
  const navigation = useNavigation();
  const fields = product ? mapProductToUI(product) : initialValues;
  const onItemPress = useCallback(
    (item: ActionableListItem) => {
      navigation.navigate(item.route as string, { ...product, manufacturerId });
    },
    [navigation, product, manufacturerId]
  );

  return (
    <>
      <ActionableList
        items={fields}
        loading={loading}
        onItemPress={onItemPress}
      />
      {product ? (
        <FloatingButton
          title="Publish"
          disabled={!determineProductIsPublished(product)}
          onPress={() => onSubmit(product)}
        />
      ) : null}
    </>
  );
};

export default AddProductForm;
