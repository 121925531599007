import React, { useMemo } from "react";
import { Product } from "models";
import { Storage } from "aws-amplify";
import * as mimeType from "react-native-mime-types";
import { startsWith, endsWith } from "lodash";
import { GetItem } from "lib/Storage";

export interface section {
  title: string;
  disabled?: boolean;
  data: data[];
}
export interface data {
  id: number;
  label?: string;
  value?: string;
  isImage?: boolean;
  isPDF?: boolean;
  isLink?: boolean;
}

export const useSections = (
  ceURI: string,
  fdaURI: string,
  isoURI: string,
  docURI: string,
  product?: Product
): section[] => {
  return useMemo(
    () =>
      [
        {
          title: "Name",
          data: [
            {
              id: 1,
              label: "",
              value: product?.name,
            },
          ],
        },
        {
          title: "Description",
          data: [
            {
              id: 2,
              label: "",
              value: product?.description,
            },
          ],
        },
        {
          title: "CE",
          data: [
            {
              id: 3,
              label: "Number",
              value: product?.ceNumber,
            },
            {
              id: 4,
              label: "Document",
              value: ceURI,
              isLink: true,
              isImage: isImage(product?.ceDocLink),
              isPDF: isPDF(product?.ceDocLink),
            },
          ],
        },
        {
          title: "FDA",
          disabled: !product?.fdaNumber || !product?.fdaDocLink,
          data: [
            {
              id: 3,
              label: "Number",
              value: product?.fdaNumber,
            },
            {
              id: 4,
              label: "Click to open",
              value: fdaURI,
              isLink: true,
              isImage: isImage(product?.fdaDocLink),
              isPDF: isPDF(product?.fdaDocLink),
            },
          ],
        },
      ].filter((item) => !item.disabled),
    [product, ceURI, fdaURI, isoURI, docURI]
  );
};

export async function getPreview(
  setter: React.Dispatch<React.SetStateAction<string>>,
  value?: string
): Promise<boolean> {
  if (value) {
    const url = await GetItem(value, {
      level: "protected",
    });
    if (isImage(value) || isPDF(value)) {
      setter(url.toString());
      return new Promise((resolve) => {
        resolve(true);
      });
    }
  }
  return new Promise((resolve) => {
    resolve(false);
  });
}

function isPDF(value?: string): boolean {
  if (!value) return false;
  return endsWith(mimeType.lookup(value), "pdf");
}
function isImage(value?: string): boolean {
  if (!value) return false;
  return startsWith(mimeType.lookup(value), "image");
}
