import { View, StyleSheet } from "react-native";
import { Text } from "react-native-elements";
import React, { FunctionComponent } from "react";
import AddButton from "components/buttons/AddButton";
import { units } from "theme";

interface Props {
  title: string;
  onPress?: () => void;
}

const ActionableHeader: FunctionComponent<Props> = ({ onPress, title }) => (
  <View style={styles.container}>
    <Text h4>{title}</Text>
    {onPress ? <AddButton onPress={onPress} /> : null}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 12,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: units.lg,
    alignSelf: "center",
    width: "100%",
  },
});

export default ActionableHeader;
