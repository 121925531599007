import React, { useState, Fragment, useCallback, useRef } from "react";
import { View, StyleSheet } from "react-native";
import {
  TextInput,
  FileUploadInput,
  UploadProgress,
  InitialProgress,
  SecondaryButton,
  Scaffold,
} from "components";
import { IProgress } from "components/UploadProgress/UploadProgress";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import useNavigationParam from "lib/use-navigation-param";
import useCustomBack from "lib/use-custom-back";
import SubmitErrorHandler from "lib/on-submit-error-handler";
import { DataStore } from "@aws-amplify/datastore";
import { Product } from "models";
import { getValuesWithUploadMechanism, updateProduct } from "./utils";
import fixNull from "lib/fix-null";

export default function ProductNioshLicense() {
  const navigation = useNavigation();
  const customBack = useCustomBack();
  const [progress, setProgress] = useState<IProgress>(InitialProgress);
  const manufacturerId = useNavigationParam("manufacturerId");
  const isSubmitting = useRef(false);

  useFocusEffect(
    useCallback(() => {
      return () => setProgress(InitialProgress);
    }, [])
  );

  const id = useNavigationParam("id");
  const nioshNumberParam = useNavigationParam("nioshNumber") || "";
  const nioshDocLinkParam = useNavigationParam("nioshDocLink");

  return (
    <Fragment>
      <Scaffold bottom>
        <Formik
          initialValues={{
            nioshNumber: fixNull(nioshNumberParam),
            nioshDocLink: fixNull(nioshDocLinkParam),
            hasNoNiosh: null,
          }}
          validationSchema={validatorSchema}
          onSubmit={async (values) => {
            if (isSubmitting.current === false) {
              isSubmitting.current = true;
              const product = await DataStore.query(Product, id);

              const result = await getValuesWithUploadMechanism(
                manufacturerId,
                product,
                values.hasNoNiosh
                  ? { nioshDocLink: null, nioshNumber: null, hasNoNiosh: true }
                  : values,
                ["nioshDocLink"],
                (progress: IProgress) => {
                  setProgress(progress);
                }
              );

              isSubmitting.current = false;
              updateProduct(product, result, manufacturerId, navigation);
            }
          }}
        >
          {({ submitForm, setFieldValue }) => {
            customBack(submitForm);
            return (
              <>
                <TextInput
                  name="nioshNumber"
                  multiline={false}
                  placeholder="License Number"
                />
                <FileUploadInput name="nioshDocLink" />
                <SecondaryButton
                  title="Not Licensed"
                  containerStyle={styles.pickerButton}
                  onPress={() => {
                    setFieldValue("hasNoNiosh", true);
                    submitForm();
                  }}
                />
                <SubmitErrorHandler />
              </>
            );
          }}
        </Formik>
      </Scaffold>
      <UploadProgress progress={progress} />
    </Fragment>
  );
}

const validatorSchema = yup.object().shape({
  nioshNumber: yup.string().nullable(),
  nioshDocLink: yup.string().nullable(),
  hasNoNiosh: yup.boolean().nullable(),
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    backgroundColor: "white",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
  },
  title: {
    textAlign: "center",
    maxWidth: "100%",
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#EFF1F2",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
  },
  pickerButton: {
    paddingVertical: 30,
  },
});
