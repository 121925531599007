import { Image, View } from "react-native";
import React from "react";

export default function SignInFormLogo() {
  return (
    <View style={{ alignSelf: "center" }}>
      <Image
        style={{ width: 200, height: 200 }}
        source={require("../../../assets/splash.png")}
      />
    </View>
  );
}
