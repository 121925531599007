import { StyleSheet } from "react-native";
import React, { FunctionComponent } from "react";
import { Col } from "react-native-easy-grid";

const centerStyle = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export const Center: FunctionComponent = ({ children }) => (
  <Col style={centerStyle.container}>{children}</Col>
);
